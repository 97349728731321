import * as React from "react";
import {BooleanRadioButton, BooleanRadioButtonGroup, Checkbox} from "@octopusdeploy/design-system-components";
import repository from "client/repository";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn,
    DataTableRowWithActionColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {HostedInstanceUsageMetricsResource} from "client/resources/hostedInstanceUsageMetricsResource";
import PaperLayout from "components/PaperLayout";
import {default as OverflowMenu} from "components/Menu/OverflowMenu";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Note, Text} from "components/form";
import {linkToInstance} from "areas/instances/linkToInstance";
import UnstructuredFormSection from "components/form/Sections/UnstructuredFormSection";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import ActionList from "components/ActionList/ActionList";
import {InstanceLimitResource} from "client/resources/instanceLimitResource";
import externalSystemLinks from "externalSystemLinks";
import TimePicker from "components/form/DatePicker/TimePicker";
import {HostedInstanceOutageWindowRequest} from "client/resources/hostedInstanceOutageWindowRequest";
import {DatabaseRestoreRequest} from "client/resources/databaseRestoreRequest";
import {InternalRedirect} from "components/Navigation/InternalRedirect";
import Select from "components/form/Select/Select";
import {ReefResource} from "client/resources/reefResource";
import TaskSnackbar from "shared/TaskSnackbar";
import routeLinks from "routeLinks";
import {LocalTime} from "shared/localTime";
import {TakeProcessDumpDialog} from "areas/instances/Instances/TakeProcessDumpDialog";
import {ReprovisionDialog} from "areas/instances/Instances/ReprovisionDialog";
import {UpgradeDialog} from "areas/instances/Instances/UpgradeDialog";
import {MoveFileStorageDialog} from "areas/instances/Instances/MoveFileStorageDialog";
import {RestoreFileShareDialog} from "areas/instances/Instances/RestoreFileShareDialog";
import {RestartConfirmDialog} from "areas/instances/Instances/RestartConfirmDialog";
import {RunSqlDialog} from "./RunSqlDialog";
import {HostedInstanceMoveReefRequest} from "client/resources/hostedInstanceMoveReefRequest";
import {HostedInstanceConfigureStorageRequest} from "client/resources/hostedInstanceConfigureStorageRequest";
import {auditLinks} from "areas/auditing/auditRouteLinks";
import {
    displayBetterUptimeStatus,
    displayInstanceStatus,
    displaySecondaryUptimeStatus
} from "areas/instances/Instances/displayInstanceStatus";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import moment from "moment";
import {ChangeUpgradeStrategyRequest} from "client/resources/changeUpgradeStrategyRequest";
import NextOutageWindowDisplay from "areas/instances/Instances/NextOutageWindowDisplay";
import OutageWindowDisplay from "areas/instances/Instances/OutageWindowDisplay";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import convertBooleanToText from "utils/convertBooleanToText";
import {HostedInstanceConfigureDatabaseRequest} from "../../../client/resources/hostedInstanceConfigureDatabaseRequest";
import {
    HostedInstanceAllowLocalHostOctopusPortalSignInRequest
} from "client/resources/HostedInstanceAllowLocalHostOctopusPortalSignInRequest";
import {
    HostedInstanceInstallLegacyCloudAuthProvidersRequest
} from "client/resources/HostedInstanceInstallLegacyCloudAuthProvidersRequest";
import Callout, {CalloutType} from "../../../components/Callout";
import {InstanceStatus} from "../../../client/resources/instanceStatus";
import {bytesToGigaBytes, gigaBytesToGigaBytes, megaBytesToGigaBytes} from "../../storageConversions";
import {HostedInstanceMasterKeyRequest} from "../../../client/resources/hostedInstanceMasterKeyRequest";
import Sensitive from "../../../components/Sensitive/Sensitive";
import {
    AssignedVersionUpgradeStrategy,
    TrackBranchUpgradeStrategy,
    UpgradeStrategyType,
    upgradeStrategyTypeDescriptions
} from "../../../client/resources/upgradeStrategy";
import {cloneDeep, head} from "lodash";
import {HostedInstanceDatabaseScaleRequest} from "client/resources/HostedInstanceConfigureDatabaseScaleRequest";
import {notSet} from "./Instances";
import {InstanceTaskLockResource} from "client/resources/instanceTaskLockResource";
import {displayTaskStatus} from "areas/backgroundTasks/displayTaskStatus";
import {EditDatabaseMaxPoolSizeDialog} from "./EditDatabaseMaxPoolSizeDialog";
import {WarnToCancelViaOctofrontAlert} from "./WarnToCancelViaOctofrontAlert";
import {ChangeDnsPrefixDialog} from "./ChangeDnsPrefixDialog";
import {InstanceEnvironmentVariableDetails} from "./InstanceEnvironmentVariableDetails";
import {InstanceAssignedEnvironmentVariableDetails} from "./InstanceAssignedEnvironmentVariableDetails";
import {
    ApplicableInstanceEnvironmentVariable,
    EnvironmentVariable
} from "client/repositories/instanceEnvironmentVariableRepository";
import {DeleteConfirmation} from "./DeleteConfirmation";
import {DowngradeDialog} from "./DowngradeDialog";
import {HostedInstanceDatabaseLimits} from "client/resources/hostedInstanceDatabaseLimits";
import {CleanUpStrategyRows} from "./CleanUpStrategyRows";
import {InstanceDetailLicenseXml} from "./InstanceDetailLicenseXml";
import {isBranchInstance} from "../../../client/resources/cleanUpStrategy";
import {RunDatabaseMaintenanceDialog} from "./RunDatabaseMaintenanceDialog";
import {OutageWindowFrequency, outageWindowFrequencyDescriptions} from "client/resources/outageWindowFrequency";
import {mapMaintenanceWindowToHostedInstanceOutageWindowRequest} from "client/resources/maintenanceWindow";
import {timeZoneSelectItems} from "./timeZones";
import {ChangeUpgradeStrategyCallout} from "./ChangeUpgradeStrategyCallout";
import {reefDisplayLabel} from "../../reefs/ReefDisplayLabel";
import {ConfigureInstanceReplicasDialog} from "./ConfigureInstanceReplicasDialog";
import {ZoneDiversityType} from "../../../client/resources/ZoneDiversityType";
import {AreYouReallySureConfirmation} from "./AreYouReallySureConfirmation";

interface State extends DataBaseComponentState {
    instance?: HostedInstanceResource;
    instanceLock?: InstanceTaskLockResource;
    instanceUsageMetrics?: HostedInstanceUsageMetricsResource;
    instanceLimit?: InstanceLimitResource;
    instanceEnvironmentVariables?: ApplicableInstanceEnvironmentVariable[];
    instanceAssignedEnvironmentVariables?: EnvironmentVariable[];
    databaseLimits?: HostedInstanceDatabaseLimits;
    reefs?: Map<string, ReefResource>;
    changeOutageWindowRequest: Partial<HostedInstanceOutageWindowRequest>;
    changeAllowLocalHostOctopusPortalSignInRequest: Partial<HostedInstanceAllowLocalHostOctopusPortalSignInRequest>;
    installLegacyCloudAuthProvidersRequest: Partial<HostedInstanceInstallLegacyCloudAuthProvidersRequest>;
    moveReefRequest: Partial<HostedInstanceMoveReefRequest>;
    restoreDatabaseRequest: Partial<DatabaseRestoreRequest>;
    changeUpgradeStrategyRequest: Partial<ChangeUpgradeStrategyRequest>;
    configureStorageRequest: Partial<HostedInstanceConfigureStorageRequest>;
    configureDatabaseRequest: Partial<HostedInstanceConfigureDatabaseRequest>;
    databaseScaleRequest: Partial<HostedInstanceDatabaseScaleRequest>;
    changeMasterKeyRequest: Partial<HostedInstanceMasterKeyRequest>;
    redirectTo?: string;
    recentlyCreatedTask?: string;
    canDelete: boolean;
    canScaleToBusinessCritical: boolean;
    environment: string;
}

interface Props {
    instanceId: string;
    breadcrumbTitle: string;
    breadcrumbChip: JSX.Element;
}

export class InstanceDetail extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            changeOutageWindowRequest: {},
            moveReefRequest: {},
            configureStorageRequest: {},
            configureDatabaseRequest: {},
            databaseScaleRequest: {},
            restoreDatabaseRequest: {},
            canDelete: false,
            canScaleToBusinessCritical: false,
            changeUpgradeStrategyRequest: {},
            changeMasterKeyRequest: {},
            changeAllowLocalHostOctopusPortalSignInRequest: {},
            installLegacyCloudAuthProvidersRequest: {},
            environment: ""
        };
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const [instance, reefs] = await Promise.all([
                repository.HostedInstances.get(this.props.instanceId),
                repository.Reefs.map()
            ]);

            const [instanceUsageMetrics, databaseLimits, instanceLimit, paginatedLocks, instanceEnvironmentVariables, instanceAssignedEnvironmentVariables] = await Promise.all([
                repository.HostedInstances.getUsage(instance.Id),
                repository.HostedInstances.getDatabaseLimits(instance.Id),
                repository.InstanceLimits.getForInstance(instance.Id),
                repository.HostedInstances.getInstanceTaskLocks({relatedToHostedInstanceIds: [instance.Id]}),
                repository.InstanceEnvironmentVariables.getInstanceEnvironmentVariableByHostedInstance(instance.Id),
                repository.InstanceEnvironmentVariables.getInstanceAssignedEnvironmentVariableByHostedInstance(instance.Id)
            ]);

            const instanceLock = head(paginatedLocks.Resources);
            const environment = (await repository.Configuration.getAppInfo()).Environment;

            this.setState({
                instance,
                instanceUsageMetrics,
                reefs,
                instanceLimit,
                instanceEnvironmentVariables,
                instanceAssignedEnvironmentVariables,
                databaseLimits,
                changeOutageWindowRequest: mapMaintenanceWindowToHostedInstanceOutageWindowRequest(instance.MaintenanceWindow),
                moveReefRequest: {
                    MoveDuringOutageWindow: true,
                    IncludeResources: true
                },
                configureStorageRequest: {
                    SharedQuotaInGb: instance.SharesQuotaInGb
                },
                configureDatabaseRequest: {
                    MaxSizeInMb: instance.DatabaseMaxSizeInMb,
                    RunDuringOutageWindow: true
                },
                restoreDatabaseRequest: {
                    PointInTime: moment.utc().toISOString()
                },
                databaseScaleRequest: {
                    RunDuringOutageWindow: true,
                    ServiceLevelObjective: instance.DatabaseServiceLevel
                },
                changeUpgradeStrategyRequest: {
                    UpgradeStrategy: cloneDeep(instance.UpgradeStrategy)
                },
                changeAllowLocalHostOctopusPortalSignInRequest: {
                    AllowLocalHostOctopusPortalSignIn: instance.AllowLocalHostOctopusPortalSignIn
                },
                installLegacyCloudAuthProvidersRequest: {
                    InstallLegacyCloudAuthProviders: this.isLegacyAuthProviders(instance.AuthProvidersToInstall),
                    ReprovisionDuringMaintenanceWindow: true
                },
                instanceLock,
                environment
            });
        });
    }

    handleDatabaseRestoreDateInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const inputDate = new Date(event.currentTarget.value);

        const pointInTime = moment.utc(this.state.restoreDatabaseRequest.PointInTime);
        pointInTime.year(inputDate.getFullYear());
        pointInTime.month(inputDate.getMonth());
        pointInTime.date(inputDate.getDate());
        pointInTime.second(0);

        this.setState({restoreDatabaseRequest: {PointInTime: pointInTime.toISOString()}});
    };

    handleDatabaseRestoreTimeInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const indexOfFirst = event.currentTarget.value.indexOf(":");
        const hours = Number(event.currentTarget.value.substring(0, indexOfFirst));
        const minutes = Number(event.currentTarget.value.substring(indexOfFirst + 1));

        const pointInTime = moment.utc(this.state.restoreDatabaseRequest.PointInTime);
        pointInTime.hour(hours);
        pointInTime.minute(minutes);
        pointInTime.second(0);

        this.setState({restoreDatabaseRequest: {PointInTime: pointInTime.toISOString()}});
    };

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }

        const instance = this.state.instance;
        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            title="Detail"
                            breadcrumbTitle={this.props.breadcrumbTitle}
                            breadcrumbChip={this.props.breadcrumbChip}
                            sectionControl={<ActionList actions={instance ? this.getActions() : []}/>}>
            {instance && this.getBody()}
        </PaperLayout>;
    }

    private getActions() {
        const instance = this.state.instance;
        const instanceLimit = this.state.instanceLimit;
        const reefResource = this.getReefFor(instance);

        const menuItems = [
            this.canBeUpgraded(instance.Status) && OverflowMenu.dialogItem("Upgrade", this.upgradeDialog()),
            this.canBeReprovisioned(instance.Status) && OverflowMenu.dialogItem("Reprovision", this.reprovisioningDialog()),
            this.canBeDowngraded(instance.Status) && OverflowMenu.dialogItem("Downgrade", this.downgradeDialog()),
            this.canBeReactivated(instance.Status) && OverflowMenu.dialogItem("Reactivate", this.reactivationDialog()),
            OverflowMenu.dialogItem("Run SQL", this.runSqlDialog()),
            OverflowMenu.dialogItem("Snapshot File Share", this.snapshotFileShareDialog()),
            this.canDataBeRestored(instance.Status) && OverflowMenu.dialogItem("Restore File Share", this.restoreFileShareDialog()),
            this.canDataBeRestored(instance.Status) && OverflowMenu.dialogItem("Restore Database", this.restoreDatabaseDialog()),
            this.isNotDeactivatedOrDeleted(instance.Status) && isBranchInstance(instance.CleanUpStrategy) && OverflowMenu.dialogItem("Add User As Admin", this.addUserAsAdminDialog()),
            this.isNotDeactivatedOrDeleted(instance.Status) && OverflowMenu.dialogItem("Take Process Dump", this.takeProcessDumpDialog()),
            this.isNotDeactivatedOrDeleted(instance.Status) && OverflowMenu.dialogItem("Run Database Maintenance", this.runDatabaseMaintenanceDialog()),
            this.isNotDeactivatedOrDeleted(instance.Status) && OverflowMenu.dialogItem("Cancel All Database Maintenance Tasks", this.cancelDatabaseMaintenanceDialog()),
            this.isNotDeactivatedOrDeleted(instance.Status) && OverflowMenu.dialogItem("Revoke All Users Sessions", this.revokeUsersSessionsDialog()),
            this.isNotDeactivatedOrDeleted(instance.Status) && OverflowMenu.dialogItem("Restart", <RestartConfirmDialog errors={this.state.errors} busy={this.state.busy}
                                                                     instanceId={this.state.instance.Id}
                                                                     onDone={taskId => this.showNewTaskWindow(taskId)}/>),
            this.canBeDeactivated(instance.Status) && OverflowMenu.deleteItem("Deactivate",
                "Are you sure you want to deactivate this instance?", () => this.handleDeactivateConfirm(),
                <WarnToCancelViaOctofrontAlert action="deactivate" instanceLimit={this.state.instanceLimit} />),
            this.state.environment === "Development" && OverflowMenu.dialogItem("Enable Azure Authentication", this.azureAuthDialog()),
            this.canBeDeleted(instance.Status) && OverflowMenu.deleteItem("Delete", "Are you sure you want to delete this instance?", () => this.handleDeleteConfirm(),
                <>
                    <WarnToCancelViaOctofrontAlert action="delete" instanceLimit={this.state.instanceLimit} />
                    <br/>
                    <DeleteConfirmation itemName={this.state.instance ? this.state.instance.DnsPrefix : ""}
                                        deleteWhat="instance" onChange={canDelete => this.setState({canDelete})}/>
                </>,
                !this.state.canDelete),
            instance.BetterUptimeRawUptimeMonitorId && OverflowMenu.externalNavItem("Better Uptime (raw)",externalSystemLinks.betterUptime.rawUptimeLinks.status(instance)),
            OverflowMenu.externalNavItem("NGINX HTTP Logs", externalSystemLinks.sumoLogic.httpAccessLogs(instance)),
            reefResource && instance.HubTenantId && OverflowMenu.externalNavItem("Hub", externalSystemLinks.hub.root(reefResource)),
            OverflowMenu.navItem("Audit Trail", auditLinks.auditTrailFor(instance)),
            this.canMasterKeyBeUpdated(instance.Status) && OverflowMenu.dialogItem("Update Master Key", this.updateMasterKeyDialog()),
        ];

        return [
            instanceLimit.CloudSubscriptionId && <ExternalLink href={externalSystemLinks.octofront.subscriptionDetails(instanceLimit)}>
                Subscription
            </ExternalLink>,
            reefResource && <ExternalLink
                href={externalSystemLinks.seq.instanceLogs(instance, reefResource)}>
                Logs (Seq)
            </ExternalLink>,
            <ExternalLink
                href={externalSystemLinks.sumoLogic.instanceLogs(instance)}>
                Logs (Sumo)
            </ExternalLink>,
            instance.BetterUptimeAlertingMonitorId && <ExternalLink href={externalSystemLinks.betterUptime.alertingLinks.status(instance)}>
                Uptime (Alerting)
            </ExternalLink>,
            reefResource && reefResource.SumoLogicInstanceDashboardId && <ExternalLink href={externalSystemLinks.sumoLogic.instanceMetrics(instance, reefResource)}>
                Metrics
            </ExternalLink>,
            <ExternalLink href={externalSystemLinks.honeycomb.instanceTraces(instance)}>
                Traces
            </ExternalLink>,
            <OverflowMenu menuItems={menuItems}/>
        ];
    }

    private canBeDeactivated(status: InstanceStatus) {
        return status !== InstanceStatus.Deleted && status !== InstanceStatus.Deleting && status !== InstanceStatus.Deactivated;
    }

    private canBeReactivated(status: InstanceStatus) {
        return status === InstanceStatus.Deactivated;
    }

    private canBeDeleted(status: InstanceStatus) {
        return status !== InstanceStatus.Deleted && status !== InstanceStatus.Deleting;
    }

    private canMasterKeyBeUpdated(status: InstanceStatus) {
        return status === InstanceStatus.Deactivated;
    }

    private canBeReprovisioned(status: InstanceStatus) {
        return status !== InstanceStatus.Deleted && status !== InstanceStatus.Deleting && status !== InstanceStatus.Deactivated;
    }

    private canBeUpgraded(status: InstanceStatus) {
        return status !== InstanceStatus.Deleted && status !== InstanceStatus.Deleting && status !== InstanceStatus.Deactivated;
    }

    private canBeDowngraded(status: InstanceStatus) {
        return status !== InstanceStatus.Deleted && status !== InstanceStatus.Deleting && status !== InstanceStatus.Deactivated;
    }

    private canDataBeRestored(status: InstanceStatus) {
        return status === InstanceStatus.Deactivated;
    }

    private canChangeDnsPrefix(status: InstanceStatus) {
        return ( status !== InstanceStatus.Deactivating &&
            status !== InstanceStatus.Deactivated &&
            status !== InstanceStatus.Deleting &&
            status !== InstanceStatus.Deleted );
    }

    private isNotDeactivatedOrDeleted(status: InstanceStatus) {
        return ( status !== InstanceStatus.Deactivating &&
            status !== InstanceStatus.Deactivated &&
            status !== InstanceStatus.Deleting &&
            status !== InstanceStatus.Deleted );
    }

    

    private getBody() {
        const instance = this.state.instance;
        const fileUsage = this.state.instanceUsageMetrics && this.state.instanceUsageMetrics.Files;
        const databaseCpuUsage = this.state.instanceUsageMetrics && this.state.instanceUsageMetrics.DatabaseCpu;
        const podMemoryUsage = this.state.instanceUsageMetrics && this.state.instanceUsageMetrics.PodMemory;
        const cpuUsage = this.state.instanceUsageMetrics && this.state.instanceUsageMetrics.Cpu;
        const databaseLimits = this.state.databaseLimits;

        const reefResource = this.getReefFor(instance);

        return <div>
            <TaskSnackbar
                getLink={() => routeLinks.instances.instance(instance.Id).tasks}
                onRequestClose={() => this.setState({recentlyCreatedTask: null})}
                open={!!this.state.recentlyCreatedTask}/>
            {this.state.instanceLock && this.getLockWarning(this.state.instanceLock)}
            <FormSectionHeading title="Ids"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Id</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.Id}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Hub Tenant Id</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.HubTenantId || "[Not Set]"}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Hosting"/>
            <DataTable>
                <DataTableBody>
                    {reefResource && <DataTableRow>
                        <DataTableRowHeaderColumn>Reef</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Move To Another Reef">
                                {this.moveToAnotherReef()}
                            </OpenDialogButton>}>
                            <InternalLink to={routeLinks.reefs.reef(reefResource.Id).root}>{reefDisplayLabel(reefResource)}</InternalLink>
                        </DataTableRowWithActionColumn>
                    </DataTableRow>}
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Kubernetes Cluster</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.KubernetesClusterName || notSet()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Storage Account</DataTableRowHeaderColumn>
                        {instance.StorageAccountName ? (<DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Move To Another Storage Account">
                                <MoveFileStorageDialog instance={this.state.instance} />
                            </OpenDialogButton>}>{instance.StorageAccountName}</DataTableRowWithActionColumn>) : (<DataTableRowColumn>{notSet()}</DataTableRowColumn>)}
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Sql Server</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.SqlServerName || notSet()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Dns Prefix</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change Dns Prefix" disabled={!this.canChangeDnsPrefix(instance.Status)}>
                                {this.changeDnsPrefixDialog()}
                            </OpenDialogButton>}>{instance.DnsPrefix}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    {reefResource && <DataTableRow>
                        <DataTableRowHeaderColumn>Domain</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{linkToInstance(instance, reefResource)}</DataTableRowColumn>
                    </DataTableRow>}
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Maintenance"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{displayInstanceStatus(instance.Status)}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Better Uptime Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{displayBetterUptimeStatus(instance.BetterUptimeStatus)}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Secondary Uptime Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{displaySecondaryUptimeStatus(instance.SecondaryUptimeStatus)}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Should Be Monitored Status</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.ShouldBeMonitored ? "Yes" : "No"}</DataTableRowColumn>
                    </DataTableRow>
                    {reefResource && <DataTableRow>
                        <DataTableRowHeaderColumn>Upgrade Strategy</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change Upgrade Strategy">
                                {this.changeUpgradeStrategyDialog()}
                            </OpenDialogButton>}>
                            {instance.UpgradeStrategy && <>
                                {upgradeStrategyTypeDescriptions[instance.UpgradeStrategy.UpgradeStrategyType]}
                                {instance.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.TrackBranchUpgradeStrategy &&
                                  <>
                                      {" ("}
                                      <ExternalLink href={`https://github.com/OctopusDeploy/OctopusDeploy/tree/${(instance.UpgradeStrategy as TrackBranchUpgradeStrategy).BranchName}`}>
                                          {(instance.UpgradeStrategy as TrackBranchUpgradeStrategy).BranchName}
                                      </ExternalLink>
                                      {")"}
                                  </>}
                                {instance.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.AssignedVersionUpgradeStrategy &&
                                    ` (${(instance.UpgradeStrategy as AssignedVersionUpgradeStrategy).Version})`}
                            </>}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>}
                    <CleanUpStrategyRows
                      instance={instance}
                      setInstance={(instance: HostedInstanceResource) => this.setState({instance})}
                      errors={this.state.errors}
                      busy={this.state.busy}
                      doBusyTask={this.doBusyTask}
                    />
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Current Version</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.Version || "[Not Set]"}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Allow Local Authentication</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change Local Authentication">
                                {this.changeAllowLocalHostOctopusPortalSignInDialog()}
                            </OpenDialogButton>}>
                            {instance.AllowLocalHostOctopusPortalSignIn ? "Yes" : "No"}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Next Outage Window</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <NextOutageWindowDisplay instance={instance}/>
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Outage Window</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change Outage Window">
                                {this.changeOutageWindowDialog()}
                            </OpenDialogButton>}>
                            <OutageWindowDisplay instance={instance}/>
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Belongs To Pool</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.IsInPool ? "Yes" : "No"}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Auth Providers</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change Auth Providers">
                                {this.installLegacyCloudAuthProvidersDialog()}
                            </OpenDialogButton>}>
                            {instance.AuthProvidersToInstall.join(", ")}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Recurring Job Schedule Offset</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.RecurringJobScheduleOffset}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>OIDC Admin External ID</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.OidcIdentityOptions?.ExternalId ?? "N/A"}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>OIDC Audience</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.OidcIdentityOptions?.Audience ?? "N/A"}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Instance Database Users</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {[instance.SqlLoginUsername, instance.ReadOnlySqlLoginUsername].join(", ")}
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Master Key Length</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {instance.MasterKeyLengthInBits} bit
                        </DataTableRowColumn>
                    </DataTableRow>

                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Resource Allocation" />
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>CPU</DataTableRowHeaderColumn>
                        <DataTableRowColumn title={cpuUsage ? "CPU usage last updated " + moment(cpuUsage.LastUpdated).fromNow() : ""}>
                            {cpuUsage ? `${cpuUsage.Usage}%` : "not available"} / Request {instance.CPURequest} - Limit {instance.CPULimit}
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Memory (RSS)</DataTableRowHeaderColumn>
                        <DataTableRowColumn title={podMemoryUsage ? "Pod memory usage last updated " + moment(podMemoryUsage.LastUpdated).fromNow() : ""}>
                            {podMemoryUsage ? `${podMemoryUsage.Usage}%` : "not available"} / Request {instance.MemoryRequest} - Limit {instance.MemoryLimit}
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>File Storage</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change storage quota">
                                {this.changeStorageQuota()}
                            </OpenDialogButton>}
                            title={fileUsage ? "File usage last updated " + moment(fileUsage.LastUpdated).fromNow() : ""}>
                                {fileUsage ? `${bytesToGigaBytes(fileUsage.CurrentValue)} / ${bytesToGigaBytes(fileUsage.Limit)} GB` : `not available / ${gigaBytesToGigaBytes(instance.SharesQuotaInGb)} GB`}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Database Storage</DataTableRowHeaderColumn>
                        {databaseLimits ? <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change database max size">
                                {this.changeDatabaseMaxSize()}
                            </OpenDialogButton>}
                            title={this.state.instanceUsageMetrics.DatabaseSize ? "Database size last updated " + moment(this.state.instanceUsageMetrics.DatabaseSize.LastUpdated).fromNow() : ""}>
                            {this.showDatabaseStorage()}
                        </DataTableRowWithActionColumn> : (<DataTableRowColumn>{this.showDatabaseStorage()}</DataTableRowColumn>)}
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Database CPU</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change Database Service Level">
                                {this.changeDatabaseScale()}
                            </OpenDialogButton>}
                            title={databaseCpuUsage ? "Database CPU usage last updated " + moment(databaseCpuUsage.LastUpdated).fromNow() : ""}>
                            {instance.DatabaseServiceLevel && <>
                                {databaseCpuUsage ? `${databaseCpuUsage.Usage.toFixed(2)}%` : "not available"}
                                {` / ${this.getVCoreCount(instance.DatabaseServiceLevel)} vCore${this.getVCoreCount(instance.DatabaseServiceLevel) == 1 ? "" : "s"} (`}
                                {`${instance.DatabaseServiceLevel})`}
                            </>}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Database Connection Max Pool Size</DataTableRowHeaderColumn>
                        <DataTableRowWithActionColumn action={
                            <OpenDialogButton label="Change max pool size">
                                {this.changeDatabaseConnectionMaxPoolSize()}
                            </OpenDialogButton>}>
                                {instance.DatabaseConnectionMaxPoolSize}
                        </DataTableRowWithActionColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Message Bus Max Concurrent Pumps</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.OctopusMessageBusMaxConcurrentPumps}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Local Cache</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            {`${instance.CacheDirectoryMaxSizeInGb} GB`}
                        </DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Replicas</DataTableRowHeaderColumn>
                        {
                            this.state.environment !== "Production"
                            ?
                                <DataTableRowWithActionColumn action={
                                    <OpenDialogButton label="Configure Instance Replicas">
                                        {this.configureInstanceReplicas()}
                                    </OpenDialogButton>}>
                                    {instance.Replicas.toString() + (instance.ZoneDiversityType == ZoneDiversityType.Forced ? " (Forced Zone Diversity)" : "")}
                                </DataTableRowWithActionColumn>
                            :
                                <DataTableRowColumn>
                                    {instance.Replicas.toString() + (instance.ZoneDiversityType == ZoneDiversityType.Forced ? " (Forced Zone Diversity)" : "")}
                                </DataTableRowColumn>
                        }
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <InstanceEnvironmentVariableDetails
                busy={this.state.busy}
                errors={this.state.errors}
                redirectToInstanceTasks={this.redirectToInstanceTasks}
                instanceEnvironmentVariables={this.state.instanceEnvironmentVariables}
                instance={instance}/>
            <InstanceAssignedEnvironmentVariableDetails
                busy={this.state.busy}
                errors={this.state.errors}
                redirectToInstanceTasks={this.redirectToInstanceTasks}
                instanceAssignedEnvironmentVariables={this.state.instanceAssignedEnvironmentVariables}
                instance={instance}/>
            <FormSectionHeading title="Dynamic Worker"/>
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Default Worker Pool Type</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.DefaultDynamicWorkerPoolType}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Worker Decommission After</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.WorkerDecommissionAfter}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Worker Idle Time Before Deletion</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.WorkerIdleTimeBeforeDeletion}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Worker Maximum Lease Duration</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{instance.WorkerMaximumLeaseDuration}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>
            <FormSectionHeading title="Cloud Subscription"/>
            {this.getCloudSubscriptionSection()}
            <FormSectionHeading title="Instance Limits"/>
            {this.getInstanceLimitSection()}
        </div>;
    }

    private showDatabaseStorage() {
        const databaseUsage = this.state.instanceUsageMetrics && this.state.instanceUsageMetrics.DatabaseSize;
        const formattedUsage = databaseUsage
            ? `${bytesToGigaBytes(databaseUsage.CurrentValue)} / ${megaBytesToGigaBytes(this.state.instance.DatabaseMaxSizeInMb)} GB`
            : `not available / ${megaBytesToGigaBytes(this.state.instance.DatabaseMaxSizeInMb)} GB`

        const allocatedStorage = this.state.instanceUsageMetrics && this.state.instanceUsageMetrics.DatabaseAllocated;
        return allocatedStorage
            ? `${formattedUsage} (${bytesToGigaBytes(allocatedStorage.CurrentValue)} GB allocated)`
            : formattedUsage;
    }

    private async moveReef(request: Partial<HostedInstanceMoveReefRequest>) {
        await this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.moveReef(this.state.instance, request as HostedInstanceMoveReefRequest);
            this.setState({instance});
        });
        await this.showNewTaskWindow();

        return true;
    }

    private configureStorage(request: Partial<HostedInstanceConfigureStorageRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.configureStorage(this.state.instance, request as HostedInstanceConfigureStorageRequest);
            const instanceUsageMetrics = await repository.HostedInstances.getUsage(instance.Id);
            this.setState({instance, instanceUsageMetrics});
            return true;
        });
    }

    private configureDatabase(request: Partial<HostedInstanceConfigureDatabaseRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.configureDatabase(this.state.instance, request as HostedInstanceConfigureDatabaseRequest);
            const instanceUsageMetrics = await repository.HostedInstances.getUsage(instance.Id);
            this.setState({instance, instanceUsageMetrics});
            return true;
        });
    }

    private scaleDatabase(request: Partial<HostedInstanceDatabaseScaleRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.scaleDatabase(this.state.instance, request as HostedInstanceDatabaseScaleRequest);
            this.setState({instance});
            return true;
        });
    }

    private changeAllowLocalHostOctopusPortalSignIn(request: Partial<HostedInstanceAllowLocalHostOctopusPortalSignInRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.changeAllowLocalHostOctopusPortalSignIn({ instance: this.state.instance, request: (request as HostedInstanceAllowLocalHostOctopusPortalSignInRequest) });
            this.setState({instance});
            return true;
        });
    }

    private installLegacyCloudAuthProviders(request: Partial<HostedInstanceInstallLegacyCloudAuthProvidersRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.installLegacyCloudAuthProviders({ instance: this.state.instance, request: (request as HostedInstanceInstallLegacyCloudAuthProvidersRequest) });
            this.setState({instance});
            return true;
        });
    }

    private changeOutageWindow(request: Partial<HostedInstanceOutageWindowRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.changeOutageWindow(this.state.instance, request as HostedInstanceOutageWindowRequest);
            this.setState({instance});
            return true;
        });
    }

    private async showNewTaskWindow(taskId?: string) {
        if (taskId != null) {
            this.setState({recentlyCreatedTask: taskId});
            return;
        }

        const tasks = await repository.BackgroundTasks.listSummaries({relatedTo: [this.props.instanceId], take: 1});
        const latest = tasks.Resources[0];
        if (latest != null && latest.Finished == null) {
            this.setState({recentlyCreatedTask: latest.Id});
        }
    }

    private snapshotFileShare() {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.snapshotFileShare(this.state.instance.Id);
            this.setState({redirectTo: routeLinks.instances.instance(this.state.instance.Id).tasks});
        });
    }

    private restoreDatabase(request: Partial<DatabaseRestoreRequest>) {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.restoreDatabase(this.state.instance, request as DatabaseRestoreRequest);
            this.setState({redirectTo: routeLinks.instances.instance(this.state.instance.Id).tasks});
        });
    }

    private moveToAnotherReef() {
        const availableReefs = Array.from(this.state.reefs
            .values())
            .filter(r => r.Id !== this.state.instance.ReefId)
            .map(r => ({text: reefDisplayLabel(r), value: r.Id}));

        return <SaveDialogLayout title={"Move Between Reefs"}
                                 busy={this.state.busy}
                                 saveButtonDisabled={!this.state.moveReefRequest.DestinationReefId}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.moveReef(this.state.moveReefRequest)}>
            <Select value={this.state.moveReefRequest.DestinationReefId}
                    onChange={DestinationReefId => this.updateMoveReefRequestReef(DestinationReefId)}
                    items={availableReefs}
                    fieldName="ReefId"
                    hintText="The reef that the instance should be moved to"/>
            <Note><b>Current Reef: </b>{reefDisplayLabel(this.getReefFor(this.state.instance))}</Note>
            <Checkbox value={this.state.moveReefRequest.IncludeResources}
                      onChange={IncludeResources => this.updateMoveReefRequest({IncludeResources})}
                      label={"Include Resources"}/>
            <div>Disable this option only if rolling back from a failed migration.</div>

            <BooleanRadioButtonGroup value={this.state.moveReefRequest.MoveDuringOutageWindow}
                              onChange={moveDuringOutageWindow => this.updateMoveReefRequest({MoveDuringOutageWindow: moveDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Move During Outage Window"/>
                <BooleanRadioButton value={false} label="Move Immediately"/>
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private changeOutageWindowDialog() {
        return <SaveDialogLayout title={"Change Outage Window"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.changeOutageWindow(this.state.changeOutageWindowRequest)}>

            <Callout type={CalloutType.Warning} title="Outage Window">
                <p>
                    The outage window for this instance is managed by Octofront and any changes to it should be made there.
                    Use this dialog only in case of emergency.
                </p>

                <p>
                    Any values specified here will be overwritten by Octofront in the future.
                </p>
            </Callout>

            <Select value={this.state.changeOutageWindowRequest.OutageWindowFrequency}
                    onChange={OutageWindowFrequency => this.updateOutageWindow({OutageWindowFrequency: OutageWindowFrequency as OutageWindowFrequency})}
                    items={Object.entries(outageWindowFrequencyDescriptions).map(([value, text]) => ({value, text}))}
                    fieldName="Frequency"/>

            <Select value={this.state.changeOutageWindowRequest.OutageWindowTimeZone}
                    onChange={OutageWindowTimeZone => this.updateOutageWindow({OutageWindowTimeZone})}
                    items={timeZoneSelectItems}
                    fieldName="Time Zone"/>

            <label>Start</label>
            <TimePicker value={LocalTime.toDate(this.state.changeOutageWindowRequest.OutageWindowStart)}
                        onChange={(value) => this.updateOutageWindow({OutageWindowStart: LocalTime.fromDate(value)})}/>

            <label>End</label>
            <TimePicker value={LocalTime.toDate(this.state.changeOutageWindowRequest.OutageWindowEnd)}
                        onChange={(value) => this.updateOutageWindow({OutageWindowEnd: LocalTime.fromDate(value)})}/>
        </SaveDialogLayout>;
    }

    private changeAllowLocalHostOctopusPortalSignInDialog() {
        return <SaveDialogLayout title={"Allow Localhost Authentication?"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.changeAllowLocalHostOctopusPortalSignIn(this.state.changeAllowLocalHostOctopusPortalSignInRequest)}>

            <Callout type={CalloutType.Warning} title="Local Octopus Portal Development">
                Normally, OctopusID will reject any request that tries to redirect to anywhere but the Octopus Cloud instance.
                Enabling this option will allow local Octopus Portal development against this Cloud instance.
            </Callout>

            <BooleanRadioButtonGroup value={this.state.changeAllowLocalHostOctopusPortalSignInRequest.AllowLocalHostOctopusPortalSignIn}
                              onChange={allowLocalhostAuthentication => this.updateAllowLocalhostAuthenticationRequest({AllowLocalHostOctopusPortalSignIn: allowLocalhostAuthentication})}>
                <BooleanRadioButton value={true} label="Allow localhost sign-on using OctopusID"/>
                <BooleanRadioButton value={false} label="Do not allow localhost sign-on"/>
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private installLegacyCloudAuthProvidersDialog() {
        return <SaveDialogLayout title={"Install Legacy Cloud Auth Providers?"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.installLegacyCloudAuthProviders(this.state.installLegacyCloudAuthProvidersRequest)}>

            <Callout type={CalloutType.Warning} title="Legacy Cloud Auth Providers">
                Installing legacy cloud auth providers will install the following auth providers: UsernamePassword, AzureAD, GoogleApps, Okta, Guest, and Octopus ID.
            </Callout>

            <Checkbox label="Reprovision During Maintenance Window"
                      value={this.state.installLegacyCloudAuthProvidersRequest.ReprovisionDuringMaintenanceWindow}
                      onChange={ReprovisionDuringMaintenanceWindow => this.updateInstallLegacyCloudAuthProviders({ReprovisionDuringMaintenanceWindow})}
            />

            <BooleanRadioButtonGroup value={this.state.installLegacyCloudAuthProvidersRequest.InstallLegacyCloudAuthProviders}
                              onChange={installLegacyCloudAuthProviders => this.updateInstallLegacyCloudAuthProviders({InstallLegacyCloudAuthProviders: installLegacyCloudAuthProviders})}>
                <BooleanRadioButton value={true} label="Enable Legacy Cloud Auth Providers"/>
                <BooleanRadioButton value={false} label="Disable Legacy Cloud Auth Providers"/>
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private changeStorageQuota() {
        return <SaveDialogLayout title={"Change storage quota in GB"}
                                 errors={this.state.errors}
                                 busy={this.state.busy}
                                 onSaveClick={() => this.configureStorage(this.state.configureStorageRequest)}>

            {this.areYouSureYouWantToIncreaseTheLimit()}
            {this.calloutManualScalingAndUpdateSpreadsheet()}

            <Text label="Quota"
                  hintText="Storage quota for this instance in GB"
                  value={this.state.configureStorageRequest.SharedQuotaInGb.toString()}
                  onChange={quota => this.updateConfigureStorageRequest({SharedQuotaInGb: Number(quota)})}
                  autoFocus={true}
            />
        </SaveDialogLayout>;
    }

    private changeDatabaseMaxSize() {
        return <SaveDialogLayout title={"Change database max size in GB"}
                                 errors={this.state.errors}
                                 busy={this.state.busy}
                                 onSaveClick={() => this.configureDatabase(this.state.configureDatabaseRequest)}>

            {this.areYouSureYouWantToIncreaseTheLimit()}

            <Callout type={CalloutType.Warning} title="Size">
                The maximum database size for this instance is currently limited by the {this.state.databaseLimits.SizeLimitSource} to {this.state.databaseLimits.MaxSizeInMb / 1024} GB.
                Larger sizes may be available by changing the database service tier, see <ExternalLink href="https://app.shortcut.com/octopusdeploy/story/20314/scaling-db-storage-size-should-scale-servicelevelobjective-if-required#activity-21316">this discussion</ExternalLink>.
            </Callout>

            {this.calloutManualScalingAndUpdateSpreadsheet()}


            <Select value={this.state.configureDatabaseRequest.MaxSizeInMb.toString()}
                    onChange={maxSize => this.updateConfigureDatabaseRequest({MaxSizeInMb: Number(maxSize)})}
                    items={Array.from(this.state.databaseLimits.AvailableDatabaseMaxSizesInMb, this.dbSize)}
                    fieldName="MaxSizeInMb"
                    hintText="Max database size for this instance"
            />

            <BooleanRadioButtonGroup value={this.state.configureDatabaseRequest.RunDuringOutageWindow}
                              onChange={scaleDuringOutageWindow => this.updateConfigureDatabaseRequest({RunDuringOutageWindow: scaleDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Scale During Outage Window" isDefault={true} />
                <BooleanRadioButton value={false} label="Scale Immediately"/>
            </BooleanRadioButtonGroup>


        </SaveDialogLayout>;
    }

    private changeDatabaseScale() {
        return <SaveDialogLayout title={"Change Database Scale"}
                                 errors={this.state.errors}
                                 busy={this.state.busy}
                                 onSaveClick={() => this.scaleDatabase(this.state.databaseScaleRequest)}
                                 saveButtonDisabled={this.isBusinessCriticalDatabaseServiceLevel(this.state.databaseScaleRequest.ServiceLevelObjective) && !this.state.canScaleToBusinessCritical}>
            {this.areYouSureYouWantToIncreaseTheDatabaseLevel()}
            {this.calloutManualScalingAndUpdateSpreadsheet()}

            <Select value={this.state.databaseScaleRequest.ServiceLevelObjective}
                    onChange={serviceLevel => {
                        this.updateDatabaseScaleRequest({ServiceLevelObjective: serviceLevel});
                    }}
                    items={[
                        this.scaleTargetVCoreItem("GP_ElasticPool"),
                        this.scaleTargetVCoreItem("GP_Gen5_2"),
                        this.scaleTargetVCoreItem("GP_Gen5_4"),
                        this.scaleTargetVCoreItem("GP_Gen5_8"),
                        this.scaleTargetVCoreItem("GP_Gen5_16"),
                        this.scaleTargetVCoreItem("GP_Gen5_32"),
                        this.scaleTargetVCoreItem("GP_Gen5_40"),
                        this.scaleTargetVCoreItem("GP_Gen5_80"),
                        this.scaleTargetVCoreItem("GP_Gen5_128"),
                        this.scaleTargetVCoreItem("BC_Gen5_2"),
                        this.scaleTargetVCoreItem("BC_Gen5_4"),
                        this.scaleTargetVCoreItem("BC_Gen5_8"),
                        this.scaleTargetVCoreItem("BC_Gen5_14"),
                        this.scaleTargetVCoreItem("BC_Gen5_24"),
                        this.scaleTargetVCoreItem("BC_Gen5_32"),
                        this.scaleTargetVCoreItem("BC_Gen5_40"),
                        this.scaleTargetVCoreItem("BC_Gen5_80"),
                        this.scaleTargetVCoreItem("BC_Gen5_128")
                    ]}
                    fieldName="ScaleDirection"
                    hintText="Service level objective to scale the database to"
            />

            {this.isBusinessCriticalDatabaseServiceLevel(this.state.databaseScaleRequest.ServiceLevelObjective) &&
            <AreYouReallySureConfirmation title={"Are you sure this database should be on the Business Critical tier?"}
                                          warning={<>Instances moving to any Business Critical database service level must be done in collaboration with <a rel="noopener" target="_blank" href="https://octopusdeploy.slack.com/archives/C01Q95KPRM4">#cloud-platform-requests</a>.</>}
                                          requiredConfirmation={"business critical"}
                                          onChange={acknowledged => this.setState({canScaleToBusinessCritical: acknowledged})} />}

            <BooleanRadioButtonGroup value={this.state.databaseScaleRequest.RunDuringOutageWindow}
                              onChange={scaleDuringOutageWindow => this.updateDatabaseScaleRequest({RunDuringOutageWindow: scaleDuringOutageWindow})}>
                <BooleanRadioButton value={true} label="Scale During Outage Window" isDefault={true} />
                <BooleanRadioButton value={false} label="Scale Immediately"/>
            </BooleanRadioButtonGroup>
        </SaveDialogLayout>;
    }

    private dbSize(sizeInMb: number){
        const text = sizeInMb < 1024 ? `${sizeInMb} MB` : `${sizeInMb / 1024} GB`;
        return {text, value: sizeInMb.toString()};
    }

    private scaleTargetVCoreItem(serviceLevelObjective: string) {
        const vCores = this.getVCoreCount(serviceLevelObjective)
        const text = `${serviceLevelObjective} (${this.getVCoreDescription(serviceLevelObjective)}, ${this.getVCoreCount(serviceLevelObjective)} vCore${vCores == 1 ? "" : "s"})`;
        return {text, value: serviceLevelObjective};
    }

    private getVCoreDescription(serviceLevel: string) {
        const dtus = new Map<string, string>([
            ["GP_ElasticPool", "General Purpose"],
            ["GP_Gen5_2", "General Purpose"],
            ["GP_Gen5_4", "General Purpose"],
            ["GP_Gen5_8", "General Purpose"],
            ["GP_Gen5_16", "General Purpose"],
            ["GP_Gen5_32", "General Purpose"],
            ["GP_Gen5_40", "General Purpose"],
            ["GP_Gen5_80", "General Purpose"],
            ["GP_Gen5_128", "General Purpose"],
            ["BC_Gen5_2", "Business Critical"],
            ["BC_Gen5_4", "Business Critical"],
            ["BC_Gen5_8", "Business Critical"],
            ["BC_Gen5_14", "Business Critical"],
            ["BC_Gen5_24", "Business Critical"],
            ["BC_Gen5_32", "Business Critical"],
            ["BC_Gen5_40", "Business Critical"],
            ["BC_Gen5_80", "Business Critical"],
            ["BC_Gen5_128", "Business Critical"]]);
        return dtus.get(serviceLevel);
    }

    private getVCoreCount(serviceLevel: string) {
        const vCores = new Map<string, number>([
            ["GP_ElasticPool", 1],
            ["GP_Gen5_2", 2],
            ["GP_Gen5_4", 4],
            ["GP_Gen5_8", 8],
            ["GP_Gen5_16", 16],
            ["GP_Gen5_32", 32],
            ["GP_Gen5_40", 40],
            ["GP_Gen5_80", 80],
            ["GP_Gen5_128", 128],
            ["BC_Gen5_2", 2],
            ["BC_Gen5_4", 4],
            ["BC_Gen5_8", 8],
            ["BC_Gen5_14", 14],
            ["BC_Gen5_24", 24],
            ["BC_Gen5_32", 32],
            ["BC_Gen5_40", 40],
            ["BC_Gen5_80", 80],
            ["BC_Gen5_128", 128]]);
        return vCores.get(serviceLevel);
    }

    private isBusinessCriticalDatabaseServiceLevel(databaseServiceLevel: string) {
        return [
            "BC_Gen5_2",
            "BC_Gen5_4",
            "BC_Gen5_8",
            "BC_Gen5_14",
            "BC_Gen5_24",
            "BC_Gen5_32",
            "BC_Gen5_40",
            "BC_Gen5_80",
            "BC_Gen5_128",
        ].includes(databaseServiceLevel);
    }

    private changeUpgradeStrategyDialog() {
        return <SaveDialogLayout title={"Change Upgrade Strategy"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.changeUpgradeStrategy(this.state.changeUpgradeStrategyRequest)}>

            <ChangeUpgradeStrategyCallout />

            <Select value={this.state.changeUpgradeStrategyRequest.UpgradeStrategy.UpgradeStrategyType}
                    onChange={(upgradeStrategyType: UpgradeStrategyType) => this.updateUpgradeStrategy({UpgradeStrategy: {UpgradeStrategyType: upgradeStrategyType}})}
                    items={Object.keys(UpgradeStrategyType).map(t => ({value: t, text: upgradeStrategyTypeDescriptions[t as UpgradeStrategyType]}))}
                    fieldName={"upgrade strategy"}
            />

            {this.state.changeUpgradeStrategyRequest.UpgradeStrategy && this.state.changeUpgradeStrategyRequest.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.TrackBranchUpgradeStrategy &&
            <Text label="Branch"
                  hintText="Branch to track, eg main"
                  value={(this.state.changeUpgradeStrategyRequest.UpgradeStrategy as TrackBranchUpgradeStrategy).BranchName}
                  onChange={(branchName: string) => this.updateUpgradeStrategy({
                      UpgradeStrategy: {
                          UpgradeStrategyType: UpgradeStrategyType.TrackBranchUpgradeStrategy,
                          BranchName: branchName
                      }
                  })}
                  autoFocus={true}
            />
            }

            {this.state.changeUpgradeStrategyRequest.UpgradeStrategy && this.state.changeUpgradeStrategyRequest.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.AssignedVersionUpgradeStrategy &&
                <Text label="Initial Version"
                    hintText="Defaults to the instance's current version; cannot be changed"
                    disabled={true}
                    value={(this.state.changeUpgradeStrategyRequest.UpgradeStrategy as AssignedVersionUpgradeStrategy).Version}
                    onChange={(version: string) => this.updateUpgradeStrategy({
                      UpgradeStrategy: {
                          UpgradeStrategyType: UpgradeStrategyType.AssignedVersionUpgradeStrategy,
                          Version: version
                      }
                  })}
            />
            }
        </SaveDialogLayout>;
    }

    private updateUpgradeStrategy(update: Partial<ChangeUpgradeStrategyRequest>) {
        const updatePartial = {...update};
        if (update.UpgradeStrategy.UpgradeStrategyType === UpgradeStrategyType.AssignedVersionUpgradeStrategy) {
            (updatePartial.UpgradeStrategy as AssignedVersionUpgradeStrategy).Version = this.state.instance.Version
        }
        return this.setState(prevState => ({changeUpgradeStrategyRequest: {...prevState.changeUpgradeStrategyRequest, ...updatePartial}}));
    }

    private changeUpgradeStrategy(request: Partial<ChangeUpgradeStrategyRequest>) {
        return this.doBusyTask(async () => {
            const instance = await repository.HostedInstances.changeUpgradeStrategy(this.state.instance, request as ChangeUpgradeStrategyRequest);
            this.setState({instance});
            return true;
        });
    }

    private changeDatabaseConnectionMaxPoolSize() {
        return <EditDatabaseMaxPoolSizeDialog instance={this.state.instance}
                                                     busy={this.state.busy}
                                                     errors={this.state.errors}
                                                     onSaveClick={this.redirectToInstanceTasks}/>;
    }

    private configureInstanceReplicas() {
        return <ConfigureInstanceReplicasDialog instance={this.state.instance}
                                              busy={this.state.busy}
                                              errors={this.state.errors}
                                              onSaveClick={this.redirectToInstanceTasks}/>;
    }

    private snapshotFileShareDialog() {
        return <SaveDialogLayout title={"Snapshot File Share"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.snapshotFileShare()}>
            <label>Snapshot file share for hosted instance {this.state.instance.DnsPrefix}?</label>
        </SaveDialogLayout>;
    }

    private restoreDatabaseDialog() {
        return <SaveDialogLayout title={"Restore Database"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel="Restore"
                                 onSaveClick={() => this.restoreDatabase(this.state.restoreDatabaseRequest)}>
            <label>Restore database for Hosted Instance {this.state.instance.DnsPrefix} (using UTC)?</label>
            <input type="date" onChange={this.handleDatabaseRestoreDateInputChange}/>
            <input type="time" onChange={this.handleDatabaseRestoreTimeInputChange}/>
        </SaveDialogLayout>;
    }

    private takeProcessDumpDialog() {
        return <TakeProcessDumpDialog instanceId={this.state.instance.Id}
                                      busy={this.state.busy}
                                      errors={this.state.errors}/>;
    }

    private addUserAsAdminDialog() {
        return <SaveDialogLayout title={"Add User As Admin"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Yes"}
                                 cancelButtonLabel={"No"}
                                 onSaveClick={() => this.addUserAsAdmin()}>
            <p>Add yourself as a branch instance administators user?</p>
        </SaveDialogLayout>;
    }

    private azureAuthDialog() {
        return <SaveDialogLayout title={"Add Azure Active Directory Login"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Yes"}
                                 cancelButtonLabel={"No"}
                                 onSaveClick={() => this.azureAuth()}>
            <p>Are you sure you want to add Azure AD login to <strong>{this.state.instance.DnsPrefix}</strong>?</p>
            <p>This will register the <ExternalLink href={"https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/appId/e1a76654-7b5d-4c01-a169-ab670cbc70c1/isMSAApp/"}>Test Instance</ExternalLink> Active Directory App on this instance.
                Which will allow anyone in the Octopus Deploy <ExternalLink href={"https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/e0a4a590-800a-4b36-b0ae-ccc263575df2"}>Department: Engineering</ExternalLink> group to login with Admin rights.</p>
        </SaveDialogLayout>;
    }

    private reprovisioningDialog() {
        return <ReprovisionDialog instance={this.state.instance}
                                  instanceLimit={this.state.instanceLimit}
                                  reef={this.getReefFor(this.state.instance)}
                                  busy={this.state.busy}
                                  errors={this.state.errors}
                                  onSaveClick={this.reprovisioningRequestSent}/>;
    }

    private upgradeDialog() {
        return <UpgradeDialog instance={this.state.instance}
                              reef={this.getReefFor(this.state.instance)}
                              busy={this.state.busy}
                              errors={this.state.errors}
                              onSaveClick={this.upgradeRequestSent}/>;
    }

    private downgradeDialog() {
        return <DowngradeDialog instance={this.state.instance}
                              reef={this.getReefFor(this.state.instance)}
                              busy={this.state.busy}
                              errors={this.state.errors}
                              onSaveClick={this.downgradeRequestSent}/>;
    }

    private changeDnsPrefixDialog() {
        return <ChangeDnsPrefixDialog instance={this.state.instance}
                              instanceLimit={this.state.instanceLimit}
                              busy={this.state.busy}
                              errors={this.state.errors}
                              onSaveClick={this.changeDnsPrefixRequestSent}/>;
    }

    private reactivationDialog() {
        return <SaveDialogLayout title={"Reactivate"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Yes"}
                                 cancelButtonLabel={"No"}
                                 onSaveClick={() => this.reactivate()}>
            <p>Are you sure you want to reactivate <strong>{this.state.instance.DnsPrefix}</strong> instance?</p>
        </SaveDialogLayout>;
    }

    private runDatabaseMaintenanceDialog() {
        return <RunDatabaseMaintenanceDialog instanceId={this.state.instance.Id}
                                             version={this.state.instance.Version}
                                             busy={this.state.busy}
                                             errors={this.state.errors}
                                             onSaveClick={this.runDatabaseMaintenanceRequestSent}/>;
    }

    private revokeUsersSessionsDialog() {
        return <SaveDialogLayout title={"Revoke users sessions"}
            busy={this.state.busy}
            errors={this.state.errors}
            saveButtonLabel={"Yes"}
            cancelButtonLabel={"No"}
            onSaveClick={() => this.revokeUsersSessions()}>
            <p>Are you sure you want to revoke all users sessions for <strong>{this.state.instance.DnsPrefix}</strong> instance?</p>
        </SaveDialogLayout>;
    }

    private cancelDatabaseMaintenanceDialog() {
        return <SaveDialogLayout title={"Cancel all Database Maintenance Tasks"}
            busy={this.state.busy}
            errors={this.state.errors}
            saveButtonLabel={"Yes"}
            cancelButtonLabel={"No"}
            onSaveClick={() => this.cancelDatabaseMaintenanceTasks()}>
            <p>Are you sure you want to cancel all running database maintenance tasks for <strong>{this.state.instance.DnsPrefix}</strong> instance?</p>
        </SaveDialogLayout>;
    }

    private runSqlDialog() {
        return <RunSqlDialog instance={this.state.instance}
                             busy={this.state.busy}
                             errors={this.state.errors}
                             onSaveClick={this.redirectToInstanceTasks}/>;

    }

    private updateMasterKeyDialog() {
        return <SaveDialogLayout title={"Update Master Key"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel={"Save"}
                                 cancelButtonLabel={"Cancel"}
                                 onSaveClick={() => this.changeMasterKey()}>

            <Callout type={CalloutType.Warning} title="Destructive Action">
                This is a one way operation and it can result in a data loss.
            </Callout>

            <Callout type={CalloutType.Information} title="Further Action">
                This change will be reflected in the hosted instance only when it gets re-provisioned.
            </Callout>


            <Sensitive label="Master Key"
                  hintText="Master Key"
                  value={this.state.changeMasterKeyRequest.MasterKey}
                  onChange={MasterKey => this.updateMasterKeyRequest({MasterKey})}
                  autoFocus={true}
            />

        </SaveDialogLayout>;
    }

    private getLockWarning(instanceLock: InstanceTaskLockResource) {
        return <Callout type={CalloutType.Warning} title={"Warning"}>
            <InternalLink to={routeLinks.instanceTaskLocks.filtered({ relatedToHostedInstanceIds: [instanceLock.HostedInstanceId]})}>Instance Lock</InternalLink> is currently held by a {displayTaskStatus(instanceLock.LockOwnerStatus)}
            <InternalLink to={routeLinks.backgroundTasks.task(instanceLock.LockOwnerId).root}> {instanceLock.LockOwnerId}</InternalLink>
        </Callout>;
    }

    private restoreFileShareDialog() {
        return <RestoreFileShareDialog instance={this.state.instance}
                                       busy={this.state.busy}
                                       errors={this.state.errors}/>;
    }

    private getReefFor(instance: HostedInstanceResource) {
        return this.state.reefs.get(instance.ReefId);
    }

    private updateOutageWindow(update: Partial<HostedInstanceOutageWindowRequest>) {
        return this.setState(prevState => ({changeOutageWindowRequest: {...prevState.changeOutageWindowRequest, ...update}}));
    }

    private updateMoveReefRequestReef(reefId: string) {
        return this.doBusyTask(async () => {
            this.updateMoveReefRequest({DestinationReefId: reefId});
        });
    }

    private updateMoveReefRequest(update: Partial<HostedInstanceMoveReefRequest>) {
        return this.setState(prevState => ({moveReefRequest: {...prevState.moveReefRequest, ...update}}));
    }

    private updateAllowLocalhostAuthenticationRequest(update: Partial<HostedInstanceAllowLocalHostOctopusPortalSignInRequest>) {
        return this.setState(prevState => ({changeAllowLocalHostOctopusPortalSignInRequest: {...prevState.changeAllowLocalHostOctopusPortalSignInRequest, ...update}}));
    }

    private updateInstallLegacyCloudAuthProviders(update: Partial<HostedInstanceInstallLegacyCloudAuthProvidersRequest>) {
        return this.setState(prevState => ({installLegacyCloudAuthProvidersRequest: {...prevState.installLegacyCloudAuthProvidersRequest, ...update}}));
    }

    private updateConfigureStorageRequest(update: Partial<HostedInstanceConfigureStorageRequest>) {
        return this.setState(prevState => ({configureStorageRequest: {...prevState.configureStorageRequest, ...update}}));
    }

    private updateMasterKeyRequest(update: Partial<HostedInstanceMasterKeyRequest>) {
        return this.setState(prevState => ({changeMasterKeyRequest: {...prevState.changeMasterKeyRequest, ...update}}));
    }

    private updateConfigureDatabaseRequest(update: Partial<HostedInstanceConfigureDatabaseRequest>) {
        return this.setState(prevState => ({configureDatabaseRequest: {...prevState.configureDatabaseRequest, ...update}}));
    }

    private updateDatabaseScaleRequest(update: Partial<HostedInstanceDatabaseScaleRequest>) {
        return this.setState(prevState => ({databaseScaleRequest: {...prevState.databaseScaleRequest, ...update}}));
    }

    private getCloudSubscriptionSection() {
        const limit = this.state.instanceLimit;

        if (!limit.CloudSubscriptionId) {
            return <UnstructuredFormSection>{"No cloud subscription associated with this instance"}</UnstructuredFormSection>;
        }

        return <DataTable>
            <DataTableBody>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Serial</DataTableRowHeaderColumn>
                    <DataTableRowColumn>
                        <ExternalLink href={externalSystemLinks.octofront.subscriptionDetails(limit)}>
                            {limit.CloudSubscriptionId}
                        </ExternalLink>
                    </DataTableRowColumn>
                </DataTableRow>
            </DataTableBody>
        </DataTable>;
    }

    private getInstanceLimitSection() {
        const instanceLimit = this.state.instanceLimit;

        return <DataTable>
            <DataTableBody>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Cloud Subscription Serial</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{instanceLimit.CloudSubscriptionSerial}</DataTableRowColumn>
                </DataTableRow>
                <InstanceDetailLicenseXml instance={this.state.instance} instanceLimit={instanceLimit} setInstanceLimit={instanceLimit => this.setState({ instanceLimit })} errors={this.state.errors} busy={this.state.busy} doBusyTask={this.doBusyTask} />
                <DataTableRow>
                    <DataTableRowHeaderColumn>Task Cap</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{instanceLimit.TaskCap}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Storage Limit (GB)</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{instanceLimit.StorageLimitInGb}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Default Retention (max. 30 days)</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{instanceLimit.DefaultRetentionInDays}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn>Use baseline resources</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{convertBooleanToText(instanceLimit.UseBaselineResources)}</DataTableRowColumn>
                </DataTableRow>
            </DataTableBody>
        </DataTable>;
    }

    private handleDeleteConfirm = async () => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.delete(this.state.instance);
            await this.redirectToInstanceTasks();
        });
    };

    private reprovisioningRequestSent = async () => {
        return this.doBusyTask(async () => {
            await this.redirectToInstanceTasks();
        });
    };

    private upgradeRequestSent = async () => {
        return this.doBusyTask(async () => {
            await this.redirectToInstanceTasks();
        });
    };

    private downgradeRequestSent = async () => {
        return this.doBusyTask(async () => {
            await this.redirectToInstanceTasks();
        });
    };

    private runDatabaseMaintenanceRequestSent = async () => {
        return this.doBusyTask(async () => {
            await this.redirectToInstanceTasks();
        });
    };

    private changeDnsPrefixRequestSent  = async () => {
        return this.doBusyTask(async () => {
            await this.redirectToInstanceTasks();
        });
    };

    private redirectToInstanceTasks = async () => {
        this.setState({redirectTo: routeLinks.instances.instance(this.state.instance.Id).tasks});
        return true;
    };

    private handleDeactivateConfirm = async () => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.deactivate(this.state.instance);
            await this.redirectToInstanceTasks();
            return true;
        });
    };

    private reactivate = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.reactivate(this.state.instance);
            await this.redirectToInstanceTasks();
        });
    }

    private revokeUsersSessions = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.revokeUsersSessions(this.state.instance);
            await this.redirectToInstanceTasks();
        });
    }

    private cancelDatabaseMaintenanceTasks = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.cancelDatabaseMaintenance(this.state.instance);
            await this.redirectToInstanceTasks();
        });
    }

    private azureAuth = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.azureAuth(this.state.instance);
            await this.redirectToInstanceTasks();
        });
    }

    private addUserAsAdmin = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.adminUser(this.state.instance);
            await this.redirectToInstanceTasks();
        });
    }

    private changeMasterKey = async (): Promise<boolean> => {
        return this.doBusyTask(async () => {
            await repository.HostedInstances.changeMasterKey(this.state.instance, this.state.changeMasterKeyRequest as HostedInstanceMasterKeyRequest);
            this.updateMasterKeyRequest({MasterKey: null});
        });
    }

    private areYouSureYouWantToIncreaseTheLimit() {
        return <Callout type={CalloutType.Warning} title="Limits">
            <p>
                We increase customer's limits only when there is no way for them to reduce their usage and stay within or close to <ExternalLink href="https://octopus.com/legal/acceptable-usage">the specified limits</ExternalLink>.
            </p>

            <p>
                The following questions should help you make the right decision

                . Increase the limit only when you can answer <strong>Yes</strong> to all questions:
            </p>

            <p>
                - Have you worked with the customer to try to reduce their usage?
            </p>
            <p>
                - Is the new limit going to solve the customer's problem permanently? Customers needing ever increasing resource limits might not be a good fit for Octopus Cloud.
            </p>

            If you are not sure how to answer the questions then please reach out to <a rel="noopener" target="_blank" href="https://octopusdeploy.slack.com/archives/C01Q95KPRM4">#cloud-platform-requests</a> for help.

        </Callout>;
    }

    private areYouSureYouWantToIncreaseTheDatabaseLevel() {
        return <Callout type={CalloutType.Warning} title="Database Service Level">
            <p>
                We should only increase the database service level if the customer is experiencing consistent slowness and/or Sql timeouts.
                This should be used sparingly on Free licenses.
            </p>

            <p>
                The following questions should help you make the right decision

                . Increase the limit only when you can answer <strong>Yes</strong> to all questions:
            </p>

            <p>
                - The Seq logs show Sql timeout error messages?
            </p>
            <p>
                - The Seq logs show slow response times on API calls?
            </p>

            If you are not sure how to answer the questions then please reach out to <a rel="noopener" target="_blank" href="https://octopusdeploy.slack.com/archives/C01Q95KPRM4">#cloud-platform-requests</a> for help.

            <p>
                If you are needing to use this feature because the automated scaling is not working please contact <a rel="noopener" target="_blank" href="https://octopusdeploy.slack.com/archives/C01Q95KPRM4">#cloud-platform-requests</a>.
            </p>
        </Callout>;
    }

    private calloutManualScalingAndUpdateSpreadsheet() {
        return <Callout type={CalloutType.Information} title="Manual Scaling">
            <p>
                When manually scaling an instance, please ensure you:
                <li>Consider disabling automatic scaling</li>
                <li>Reach out to <ExternalLink href={"https://octopusdeploy.slack.com/archives/C01Q95KPRM4"}>#cloud-platform-requests</ExternalLink> to tell us what's happening</li>
                <li>Fill out <ExternalLink href={"https://docs.google.com/spreadsheets/d/1SgpASOAUXT35FUmyuzzx0lfBW7m2TD1fe9JPno_WTyg/edit?usp=sharing"}>the spreadsheet</ExternalLink> to document what instances are scaled manually.</li>
            </p>
        </Callout>;
    }

    private isLegacyAuthProviders(authProviders: string[]) {
        const legacyProviders = ["UsernamePassword", "AzureAD", "GoogleApps", "Okta", "OctopusID", "Guest"];
        const nonLegacyProviders = ["OctopusID", "Guest"]
        if (this.arrayEqual(authProviders, legacyProviders))
            return true;

        if (this.arrayEqual(authProviders, nonLegacyProviders))
            return false;

        return null;
    }

    private arrayEqual(arr1: string[], arr2: string[]) {
        const arr2Sorted = arr2.slice().sort();
        return arr1.length === arr2.length && arr1.slice().sort().every(function(value, index) {
            return value === arr2Sorted[index];
        });
    }
}
