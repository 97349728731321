import * as React from "react";
import PaperLayout from "components/PaperLayout";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {MeResource} from "client/resources/meResource";
import {Snackbar} from "material-ui";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import session from "session";
import {SupportUserResource} from "client/resources/supportUserResource";
import ExternalLink from "../../../components/Navigation/ExternalLink";
import externalSystemLinks from "../../../externalSystemLinks";
import styles = require("./style.less");
import {InstanceLimitResource} from "../../../client/resources/instanceLimitResource";
import {isBranchInstance} from "client/resources/cleanUpStrategy";
import {HubResource} from "client/resources/hubResource";

interface Props {
    instanceId: string;
    breadcrumbTitle: string;
    breadcrumbChip: JSX.Element;
}

interface State extends DataBaseComponentState {
    users?: SupportUserResource[];
    instance?: HostedInstanceResource;
    instanceLimit?: InstanceLimitResource;
    hub?: HubResource | null;
    me?: MeResource;
    message?: React.ReactNode;
}

class SupportUserDataTable extends SimpleDataTable<SupportUserResource> {
}

class InstanceSupportUsers extends DataBaseComponent<Props, State> {
    private refreshInitialised: boolean;
    private doRefresh: Refresh = () => Promise.resolve();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const [supportUsers, instance, me] = await Promise.all([
                repository.SupportUsers.list({instanceId: this.props.instanceId}),
                repository.HostedInstances.get(this.props.instanceId),
                repository.Authentication.me()
            ]);
            const [instanceLimit, hub] = await Promise.all([
                repository.InstanceLimits.getForInstance(instance.Id),
                repository.Hubs.tryGetForInstance(instance.Id)
            ]);

            this.setState({instance, me, users: supportUsers, instanceLimit, hub});
            this.doRefresh = await this.startRefreshLoop(() => this.reload(), 5000);
        });
    }

    render() {
        const users = this.state.users;
        const featureToggles = session.featureFlags.Features;


        return <PaperLayout busy={this.state.busy}
                             title={users && "Support Users"}
                             breadcrumbTitle={this.props.breadcrumbTitle} 
                             breadcrumbChip={this.props.breadcrumbChip}
                             errors={this.state.errors}>
            {users && this.getBody()}
            <Snackbar message={this.state.message || ""} open={!!this.state.message}
                      onRequestClose={() => this.setState({message: null})}/>
        </PaperLayout>;
    }

    getBody() {
        if (isBranchInstance(this.state.instance.CleanUpStrategy)) {
            return <label className={styles.supportUserDescription}>Branch instance admin users are now managed from Cloud Portal. Please see <ExternalLink href="https://octopushq.atlassian.net/wiki/spaces/RND/pages/1262256152/Use+a+Core+Feature+Branch+Instance#How-can-I-get-full-Admin-access-to-an-instance%3F">How can I get full Admin access to an instance?</ExternalLink></label>
        }

        return <>
            <SupportUserHelpText isHubInstance={this.isHubInstance()} instanceLimit={this.state.instanceLimit} />
            <SupportUserDataTable
                data={this.state.users}
                onRow={user => this.buildRow(user)}
                headerColumns={["Username"]}
                onEmpty={<div>This instance does not have any support users</div>}/>
        </>
    }

    private buildRow(user: SupportUserResource) {
        return [
            user.Email
        ];
    }

    private async reload() {
        const usersP = repository.SupportUsers.list({instanceId: this.props.instanceId});
        this.setState({users: await usersP });
        return {};
    }

    private isHubInstance() {
        return !!this.state.hub;
    }
}

function SupportUserHelpText({ isHubInstance, instanceLimit }: { isHubInstance: boolean; instanceLimit: InstanceLimitResource; }) {
    if (isHubInstance) {
        return <label className={styles.supportUserDescription}>Hub instance support users are now managed from Cloud Portal. Please see <ExternalLink href="https://github.com/OctopusDeploy/hosted-docs/blob/master/how-to/add-a-hub-support-user.md">How To: Add a Hub Support User</ExternalLink></label>;
    }

    return <label className={styles.supportUserDescription}>Support users are now managed from the <ExternalLink href={externalSystemLinks.octofront.supportUsers(instanceLimit)}>Commercial Admin Portal</ExternalLink></label>;
}

export default InstanceSupportUsers;
