import {HostedInstanceOutageWindowRequest} from "./hostedInstanceOutageWindowRequest";
import {OutageWindowFrequency} from "./outageWindowFrequency";

export type MaintenanceWindow = {
    StartTime: string;
    EndTime: string;
    TimeZone: string;
} & ({
    Frequency: "Daily";
} | {
    Frequency: "Monthly";
    WeekOfMonth: "First" | "Second" | "Third";
    DayOfWeek: "Monday";
})

export function mapMaintenanceWindowToHostedInstanceOutageWindowRequest(maintenanceWindow: MaintenanceWindow): HostedInstanceOutageWindowRequest {
    let frequency;

    switch (maintenanceWindow.Frequency) {
        case "Daily":
            frequency = OutageWindowFrequency.Daily;
            break;
        case "Monthly":
            switch (maintenanceWindow.WeekOfMonth) {
                case "First":
                    frequency = OutageWindowFrequency.FirstMondayOfEachMonth;
                    break;
                case "Second":
                    frequency = OutageWindowFrequency.SecondMondayOfEachMonth;
                    break;
                case "Third":
                    frequency = OutageWindowFrequency.ThirdMondayOfEachMonth;
                    break;
            }
            break;
    }

    return {
        OutageWindowFrequency: frequency,
        OutageWindowTimeZone: maintenanceWindow.TimeZone,
        OutageWindowStart: maintenanceWindow.StartTime,
        OutageWindowEnd: maintenanceWindow.EndTime
    }
}
