import React from "react";
import {css} from "@emotion/css";
import {LinearProgress} from "@octopusdeploy/design-system-components";
import {colorScales, themeTokens} from "@octopusdeploy/design-system-tokens";
import repository from "client/repository";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import routeLinks from "routeLinks";
import {MetricItem, Metrics} from "areas/dashboard/MetricItem";
import {InstanceStatus} from "client/resources/instanceStatus";
import BugReport from "material-ui/svg-icons/action/bug-report";
import Chart from "material-ui/svg-icons/editor/insert-chart";
import Code from "material-ui/svg-icons/action/code";
import {Snackbar} from "material-ui";
import {AlertItem, Alerts, LimitItem} from "./Alerts";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {ReefResource} from "client/resources/reefResource";
import {dynamicWorkerServicesRouteLinks} from "areas/dynamicWorkerServices/dynamicWorkerServicesRouteLinks";
import {HostedInstanceLookupResource} from "client/resources/hostedInstanceLookupResource";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import {DashboardResource} from "client/resources/dashboardResource";
import moment from "moment";
import {BetterUptimeStatus} from "client/resources/betterUptimeStatus";
import {getBetterUptimeColorFor, getBetterUptimeIconFor} from "shared/BetterUptimeStatuses";
import {DynamicWorkerServiceResource} from "client/resources/dynamicWorkerServiceResource";
import ToolTip from "components/ToolTip";
import {EvictionResource} from "client/resources/evictionResource";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SimpleDataTable from "components/SimpleDataTable";
import {InstanceAlertDetails, InstanceAlerts} from "./InstanceAlerts";
import {InstanceMetricUsageResource} from "client/resources/instanceMetricUsageResource";
import {getAttentionIndicator, warning} from "./getAttentionIndicator";
import PageTitleHelper from "utils/PageTitleHelper";
import ErrorPanel from "components/ErrorPanel";
import {AlertSnoozer, getAlertSnoozerForEnvironment} from "./AlertSnoozing";
import DashboardMetricType from "./DashboardMetricType";
import ExternalLink from "../../components/Navigation/ExternalLink";
import {AttentionLevel} from "../../client/resources/attentionLevel";
import {shortProcessName} from "../../shared/shortProcessName";
import externalSystemLinks from "../../externalSystemLinks";
import {PodRestartAlertItem} from "./PodRestartAlert";
import {InstanceNearingPlatformLimitAlertItem} from "./InstanceNearingPlatformLimitAlert";

const iconColor = themeTokens.color.icon;
const textColor = themeTokens.color.text;

const containerStyle = css`
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
`;

const autoUpgradeBannerStyle = css`
    display: flex;
    justify-content: center;
    color: ${colorScales.white};
    background-color: ${themeTokens.color.border.danger};
    padding: 7px;
    width: 100%;
    border-radius: 5px;
    font-weight: 500;
`;

interface State extends DataBaseComponentState {
    data?: DashboardResource;
    reefs: Map<string, ReefResource>;
    instances: Map<string, HostedInstanceLookupResource>;
    workerServices: Map<string, DynamicWorkerServiceResource>;
}

class EvictionDataTable extends SimpleDataTable<EvictionResource> {
}

class Dashboard extends DataBaseComponent<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            reefs: new Map<string, ReefResource>(),
            instances: new Map<string, HostedInstanceLookupResource>(),
            workerServices: new Map<string, DynamicWorkerServiceResource>()
        };
    }

    private alertSnoozer: AlertSnoozer;

    async componentDidMount() {
        PageTitleHelper.setRootPageTitle()

        await this.doBusyTask(async () => {
            const environment = (await repository.Configuration.getAppInfo()).Environment;
            this.alertSnoozer = getAlertSnoozerForEnvironment(environment);

            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
        });
    }

    renderErrorPanel() {
        const errors = this.state.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message}
            details={errors.details}
            detailLinks={errors.detailLinks}
            helpText={errors.helpText}
            fullException={errors.fullException}
            helpLink={errors.helpLink}
        />;
    }

    renderAutoUpgradeBanner() {
        return <div className={autoUpgradeBannerStyle}>
            <span>Auto upgrades are disabled</span>
        </div>;
    }

    renderErrorSnackbar() {
        const errors = this.state.errors;
        if (!errors) {
            return null;
        }
        const bodyStyle = {
            backgroundColor: themeTokens.color.border.warning,
            color: colorScales.white,
            maxWidth: ""
        };
        return <Snackbar
            bodyStyle={bodyStyle}
            open={true}
            message={ `Error refreshing dashboard. See Dev Tools (${errors.message}).` } />;
    }

    renderBetterUptimeMetricItem(status: BetterUptimeStatus, value: number, autoHide: boolean = false) {
        if (autoHide && value == 0)
            return <></>;

        return <MetricItem name={status} value={value}
                           color={getBetterUptimeColorFor(status, value > 0)}
                           icon={getBetterUptimeIconFor(status, value > 0)}
                           link={() => routeLinks.instances.filtered({
                               betterUptimeStatus: status,
                               shouldBeMonitored: true
                           })}/>
    }

    render() {
        const data = this.state.data;
        const errors = this.state.errors;
        if (!data) {
            return <div>
                <LinearProgress variant="indeterminate" show={!errors} />
                {this.renderErrorPanel()}
            </div>;
        }
        const groupedEvictions = new Map<string, EvictionResource[]>();
        data.PodEvictions.Data.forEach(element => {
            if (groupedEvictions.has(element.Namespace) === false) {
                groupedEvictions.set(element.Namespace, []);
            }
            groupedEvictions.get(element.Namespace).push(element);
        });
        return <div className={containerStyle}>
            {!data.AutoUpgradesEnabled && this.renderAutoUpgradeBanner()}
            {this.renderErrorSnackbar()}
            <Metrics header="Instances By BU Status" lastUpdated={data.BetterUptimeSummary.LastUpdated} freshness={"hours"}>
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Down, data.BetterUptimeSummary.Data.Down)}
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Paused, data.BetterUptimeSummary.Data.Paused)}
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Unknown, data.BetterUptimeSummary.Data.Unknown)}
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Pending, data.BetterUptimeSummary.Data.Pending, true)}
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Maintenance, data.BetterUptimeSummary.Data.Maintenance, true)}
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Validating, data.BetterUptimeSummary.Data.Validating, true)}
                {this.renderBetterUptimeMetricItem(BetterUptimeStatus.Up, data.BetterUptimeSummary.Data.Up)}
            </Metrics>

            <Metrics header="Instances By Status" lastUpdated={data.InstancesByStatusSummary.LastUpdated} freshness={"hours"}>
                {Object.entries(data.InstancesByStatusSummary.Data).map(([status, count]) => <MetricItem
                    key={status}
                    name={status}
                    icon={<Chart
                        color={status === InstanceStatus.CustomizationFailed || status === InstanceStatus.ProvisioningFailed ? iconColor.danger : iconColor.subtle}/>}
                    value={count}
                    color={status === InstanceStatus.CustomizationFailed || status === InstanceStatus.ProvisioningFailed ? textColor.danger : textColor.success}
                    link={() => routeLinks.instances.filtered({status: [status as InstanceStatus]})}/>)}
            </Metrics>

            <Metrics header="Live Instances By Version" lastUpdated={data.InstancesByVersion.LastUpdated} freshness={"hours"}>
                {Object.entries(data.InstancesByVersion.Data).map(([_, version]) => <MetricItem
                    key={version.Version || "no-version"}
                    name={`${version.Version}${version.IsDefaultAssignedVersion ? " (default)" : ""}`}
                    icon={<Code color={iconColor.subtle} />}
                    value={version.InstanceCount}
                    color={version.HasOpenProblems ? textColor.warning : textColor.success}
                    tooltip={version.ToolTip}
                    link={() => routeLinks.instances.filtered({version: version.Version, status: [InstanceStatus.Live]})}/>)}
            </Metrics>

            <Metrics header="Live Instances By Problem" lastUpdated={data.InstancesByProblem.LastUpdated} freshness={"hours"}>
                {Object.entries(data.InstancesByProblem.Data).map(([_, problem]) => <MetricItem
                    key={problem.Id}
                    name={problem.Title}
                    icon={<BugReport color={iconColor.subtle} />}
                    value={problem.CurrentValue}
                    color={getAttentionIndicator(problem.AttentionLevel).color}
                    tooltip={problem.ToolTip}
                    link={() => problem.Link}/>)}
            </Metrics>

            <InstanceAlerts
                header="File Usage In GB"
                lastUpdated={data.InstanceFileUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.InstanceFileUsage.Data, this.state.instances, DashboardMetricType.InstanceFileUsage)}/>

            <InstanceAlerts
                header="Database Usage In GB"
                lastUpdated={data.InstanceDatabaseSizeUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.InstanceDatabaseSizeUsage.Data, this.state.instances, DashboardMetricType.InstanceDatabaseSizeUsage)}/>

            <InstanceAlerts
                header="Relative Database CPU Usage 95th Over 72h"
                lastUpdated={data.RelativeInstanceDatabaseCpuUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.RelativeInstanceDatabaseCpuUsage.Data, this.state.instances, DashboardMetricType.RelativeInstanceDatabaseCpuUsage)}/>

            <InstanceAlerts
                header="Relative Database Data IO Usage 95th Over 72h"
                lastUpdated={data.RelativeInstanceDatabaseDataIoUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.RelativeInstanceDatabaseDataIoUsage.Data, this.state.instances, DashboardMetricType.RelativeInstanceDatabaseDataIoUsage)}/>

            <InstanceAlerts
                header="Relative Database Log IO Usage 95th Over 72h"
                lastUpdated={data.RelativeInstanceDatabaseLogIoUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.RelativeInstanceDatabaseLogIoUsage.Data, this.state.instances, DashboardMetricType.RelativeInstanceDatabaseLogIoUsage)}/>

            <InstanceAlerts
                header="Relative Memory (RSS) Usage 95th Over 72h"
                lastUpdated={data.RelativeInstancePodMemoryUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.RelativeInstancePodMemoryUsage.Data, this.state.instances, DashboardMetricType.InstanceMemoryUsage)}/>

            <InstanceAlerts
                header="Relative CPU Usage 95th Over 72h"
                lastUpdated={data.RelativeInstanceCpuUsage.LastUpdated}
                freshness={"hours"}
                data={this.combineInstanceMetricUsageDetails(data.RelativeInstanceCpuUsage.Data, this.state.instances, DashboardMetricType.RelativeInstanceCpuUsage)}/>

            <InstanceAlerts
                header={data.ShowRawUptimeOnDashboard ?  "Uptime (raw) Last 7 days" : "Uptime (alerting) Last 7 days"}
                lastUpdated={data.InstanceUptime.LastUpdated}
                freshness={"days"}
                data={data.InstanceUptime.Data
                    .filter(uptime => this.state.instances.has(uptime.HostedInstanceId))
                    .map(uptime => ({
                        ...uptime,
                        ...this.state.instances.get(uptime.HostedInstanceId),
                    }))
                    // The reason why there's a second map statement is because we depend on ShortId which is
                    // calculated as a part of the previous map, and it's a good practice 
                    // to use small, focused functions (avoid multiple statements in each map)
                    .map(uptime => ({
                        ...uptime,
                        isSnoozed: this.alertSnoozer.shouldSnoozeInstanceAlert(DashboardMetricType.InstanceUptime, uptime.ShortId)
                    }))
                    .sort(this.byNonSnoozedFirst)
                    .map(({HostedInstanceId, AttentionLevel, Uptime, DnsPrefix, isSnoozed}) => ({
                        HostedInstanceId,
                        AttentionLevel,
                        MetricValueInPercents: Uptime,
                        DnsPrefix,
                        SnoozeAlert: isSnoozed
                    }))}
            />

            <Alerts header="Elastic Pool SQL Instance CPU Usage 95th Over 24h" lastUpdated={data.SqlServerCpuUsage.LastUpdated} freshness={"hours"}>
                {data.SqlServerCpuUsage.Data.map(ep => {
                    const attentionIndicator = getAttentionIndicator(ep.AttentionLevel);

                    return <AlertItem key={ep.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink href={externalSystemLinks.azureMonitorLinks.createElasticPoolSqlInstanceCpuUsedPercentLink(ep.ResourceId)}>{ep.Name}</ExternalLink>}
                                      value={`${ep.Usage}%`}/>;
                })}
            </Alerts>

            <Alerts header="Elastic Pool CPU Usage 95th Over 24h" lastUpdated={data.ElasticPoolCpuUsage.LastUpdated} freshness={"hours"}>
                {data.ElasticPoolCpuUsage.Data.map(ep => {
                    const attentionIndicator = getAttentionIndicator(ep.AttentionLevel);

                    return <AlertItem key={ep.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink href={externalSystemLinks.azureMonitorLinks.createElasticPoolCpuUsedPercentLink(ep.ResourceId)}>{ep.Name}</ExternalLink>}
                                      value={`${ep.Usage}%`}/>;
                })}
            </Alerts>

            <Alerts header="Elastic Pool Data IO Usage 95th Over 24h" lastUpdated={data.ElasticPoolDataIoUsage.LastUpdated} freshness={"hours"}>
                {data.ElasticPoolDataIoUsage.Data.map(ep => {
                    const attentionIndicator = getAttentionIndicator(ep.AttentionLevel);

                    return <AlertItem key={ep.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink href={externalSystemLinks.azureMonitorLinks.createElasticPoolDataIoUsedPercentLink(ep.ResourceId)}>{ep.Name}</ExternalLink>}
                                      value={`${ep.Usage}%`}/>;
                })}
            </Alerts>

            <Alerts header="Elastic Pool Log IO Usage 95th Over 24h" lastUpdated={data.ElasticPoolLogIoUsage.LastUpdated} freshness={"hours"}>
                {data.ElasticPoolLogIoUsage.Data.map(ep => {
                    const attentionIndicator = getAttentionIndicator(ep.AttentionLevel);

                    return <AlertItem key={ep.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink href={externalSystemLinks.azureMonitorLinks.createElasticPoolLogIoUsedPercentLink(ep.ResourceId)}>{ep.Name}</ExternalLink>}
                                      value={`${ep.Usage}%`}/>;
                })}
            </Alerts>

            <Alerts header="Elastic Pool Storage Usage 95th Over 24h" lastUpdated={data.SqlServerStorageUsage.LastUpdated} freshness={"hours"}>
                {data.SqlServerStorageUsage.Data.map(ep => {
                    const attentionIndicator = getAttentionIndicator(ep.AttentionLevel);

                    return <AlertItem key={ep.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink href={externalSystemLinks.azureMonitorLinks.createElasticPoolDataSpaceUsedPercentLink(ep.ResourceId)}>{ep.Name}</ExternalLink>}
                                      value={`${ep.Usage}%`}/>;
                })}
            </Alerts>

            <Alerts header="Elastic Pool Storage Allocated" lastUpdated={data.SqlServerStorageAllocated.LastUpdated} freshness={"hours"}>
                {data.SqlServerStorageAllocated.Data.map(ep => {
                    const attentionIndicator = getAttentionIndicator(ep.AttentionLevel);

                    return <AlertItem key={ep.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink href={externalSystemLinks.azureMonitorLinks.createElasticPoolDataSpaceAllocatedPercentLink(ep.ResourceId)}>{ep.Name}</ExternalLink>}
                                      value={`${ep.Usage}%`}/>;
                })}
            </Alerts>

            <Alerts header="Storage Throttling Over 24h" lastUpdated={data.StorageAccountThrottleData.LastUpdated} freshness={"hours"}>
                {data.StorageAccountThrottleData.Data.map(u => {
                    const attentionIndicator = getAttentionIndicator(u.AttentionLevel);

                    return <AlertItem key={u.StorageAccountName}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<ExternalLink
                                          href={externalSystemLinks.azureMonitorLinks.createStorageThrottlingMetricsLink(u.ResourceId)}>{u.StorageAccountName}</ExternalLink>}
                                      value={u.ThrottleCount}/>;
                })}
            </Alerts>

            <Alerts header="Topic Storage Usage" lastUpdated={data.TopicMetrics.LastUpdated} freshness={"hours"}>
                {data.TopicMetrics.Data.map(t => {
                    const attentionIndicator = getAttentionIndicator(t.AttentionLevel);

                    return <AlertItem key={t.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={t.Name}
                                      value={`${t.Usage}%`}/>;
                })}
            </Alerts>
            <Alerts header="Topic Subscription Counts" lastUpdated={data.TopicSubscriptionMetrics.LastUpdated} freshness={"hours"}>
                {data.TopicSubscriptionMetrics.Data.map(c => {
                    const attentionIndicator = getAttentionIndicator(c.AttentionLevel);
                    return <AlertItem key={c.FullName}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={c.FullName}
                                      value={c.Counts}/>;
                })}
            </Alerts>
            <Alerts header="DW Initial Lease 95th Over 24h In Sec" lastUpdated={data.DynamicWorkersInitialLeaseTimeData.LastUpdated} freshness={"hours"}>
                {[...this.state.workerServices]
                    .map(([workerServiceId, workerService]) => ({
                        ...data.DynamicWorkersInitialLeaseTimeData.Data.find(d => d.DynamicWorkerServiceId === workerServiceId),
                        serviceName: workerService.Name,
                        DynamicWorkerServiceId: workerService.Id
                    }))
                    .map(leaseTime => ({
                        ...leaseTime,
                        isSnoozed:
                            this.alertSnoozer.shouldSnoozeAlert(DashboardMetricType.DynamicWorkersInitialLeaseTime),
                    }))
                    .sort(this.byNonSnoozedFirst)
                    .map(leaseTime => ({
                        ...leaseTime,
                        ...getAttentionIndicator(leaseTime.AttentionLevel ?? AttentionLevel.Expired, leaseTime.isSnoozed)
                    }))
                    .map(({DynamicWorkerServiceId, icon, color, serviceName, TimeInMilliseconds}) => (
                        <AlertItem key={DynamicWorkerServiceId}
                                   icon={icon}
                                   color={color}
                                   text={<InternalLink
                                       to={dynamicWorkerServicesRouteLinks.service(DynamicWorkerServiceId).workers}>{serviceName}</InternalLink>}
                                   value={TimeInMilliseconds != null ?
                                       <span>{moment.duration(TimeInMilliseconds, "milliseconds").asSeconds().toFixed(1)}</span> : "N/A"}/>

                    ))}
            </Alerts>

            <Alerts header="Pod restarted in last 7 days" lastUpdated={data.PodRestarts.LastUpdated} freshness={"days"}>
                {data.PodRestarts.Data.length === 0 && <span>No Restarts</span>}
                {data.PodRestarts.Data
                    .map(restart => ({
                        ...restart,
                        isSnoozed:
                            this.alertSnoozer.shouldSnoozeInstanceAlert(DashboardMetricType.PodRestarts, restart.Namespace)
                    }))
                    .sort(this.byNonSnoozedFirst)
                    .map(restart => ({
                        restart,
                        ...getAttentionIndicator(AttentionLevel.Warning, restart.isSnoozed)
                    }))
                    .map(({restart, icon, color}) => (
                        <PodRestartAlertItem key={`${restart.ClusterName}-${restart.PodName}`}
                           icon={icon}
                           color={color}
                           restartResource={restart}
                           hostedInstance={this.state.instances.get(restart.HostedInstanceId)}
                        />
                    ))}
            </Alerts>

            <Alerts header="Pod Evictions Last 7 Days" lastUpdated={data.PodEvictions.LastUpdated} freshness={"days"}>
                {data.PodEvictions.Data.length === 0 && <span>No Evictions</span>}
                {Array.from(groupedEvictions.keys()).map(namespace => {
                    const attentionIndicator = warning();

                    const summary = <React.Fragment>
                        <EvictionDataTable
                            data={groupedEvictions.get(namespace)}
                            onRow={entry => this.buildPodEvictionRow(entry)}
                            headerColumns={["Pod Name", "Eviction Date", "Message"]}
                            onEmpty={<div>No eviction records</div>}/>
                    </React.Fragment>;
                    return <AlertItem key={`${groupedEvictions.get(namespace)[0].ClusterName}-${namespace}`}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={<OpenDialogButton label={namespace}>
                                          <InfoDialogLayout
                                              title={namespace}
                                              busy={null}
                                              errors={null}>
                                              {summary}
                                          </InfoDialogLayout>
                                      </OpenDialogButton>}
                                      value={groupedEvictions.get(namespace).length} />;
                })}
            </Alerts>

            <Alerts header="Recurring Task Status" lastUpdated={data.RecurringBackgroundTasks.LastUpdated} freshness={"hours"}>
                {data.RecurringBackgroundTasks.Data.length === 0 && <span>No Alerts</span>}
                {data.RecurringBackgroundTasks.Data.map(task => {
                        const attentionIndicator = getAttentionIndicator(task.AttentionLevel);
                        return  <AlertItem key={task.ProcessId}
                                           icon={attentionIndicator.icon}
                                           color={attentionIndicator.color}
                                           title={task.Message}
                                           text={task.Id ? <InternalLink
                                                   to={routeLinks.backgroundTasks.task(task.Id).root}>{shortProcessName(task.ProcessId)}</InternalLink>
                                               : shortProcessName(task.ProcessId)
                                           } value={task.Created ? <span><FriendlyLocalTime time={task.Created}/></span> : <span>-</span>}/>;
                    }
                )}
            </Alerts>

            <Alerts header="Problematic Locks" lastUpdated={data.ProblematicInstanceTaskLocks.LastUpdated} freshness={"hours"}>
                {data.ProblematicInstanceTaskLocks.Data
                    .map(lock => ({
                        ...lock,
                        isSnoozed: this.alertSnoozer.shouldSnoozeInstanceAlert(
                            DashboardMetricType.ProblematicInstanceTaskLocks, lock.ShortId)
                    }))
                    .sort(this.byNonSnoozedFirst)
                    .map(lock => ({
                        ...lock,
                        ...getAttentionIndicator(lock.AttentionLevel, lock.isSnoozed)
                    }))
                    .map(({HostedInstanceId, color, icon, LockStatus}) => (
                        <AlertItem key={HostedInstanceId}
                                   color={color}
                                   icon={icon}
                                   text={<InternalLink to={routeLinks.instanceTaskLocks.filtered({ relatedToHostedInstanceIds: [HostedInstanceId]})}>
                                       {this.state.instances.get(HostedInstanceId).DnsPrefix}</InternalLink>}
                                   value={LockStatus}/>
                    ))}
            </Alerts>

            <Alerts header="Certificate Expiry" lastUpdated={data.CertificateExpiry.LastUpdated} freshness={"days"}>
                {data.CertificateExpiry.Data
                    .map(ce => {
                        const attentionIndicator = getAttentionIndicator(ce.AttentionLevel);

                        return <AlertItem key={ce.Thumbprint}
                                          icon={attentionIndicator.icon}
                                          color={attentionIndicator.color}
                                          text={<span><ToolTip content={ce.Thumbprint}>{ce.SubjectName} ({ce.Count})</ToolTip></span>}
                                          value={<span><FriendlyLocalTime time={ce.NotAfter} /></span>}/>;
                    })}
            </Alerts>

            <Alerts header="Client Secret Expiry" lastUpdated={data.ClientSecretExpiry.LastUpdated} freshness={"days"}>
                {data.ClientSecretExpiry.Data
                    .map(spe => {

                        const attentionIndicator = getAttentionIndicator(spe.AttentionLevel);

                        return <AlertItem key={spe.ApplicationName}
                                          icon={attentionIndicator.icon}
                                          color={attentionIndicator.color}
                                          text={spe.ApplicationName}
                                          value={<span><FriendlyLocalTime time={spe.NotAfter}/></span>}/>;
                    })}
            </Alerts>

            <Alerts header="Instances Nearing Platform Limits" lastUpdated={data.InstancesNearingPlatformLimits.LastUpdated} freshness={"days"}>
                {data.InstancesNearingPlatformLimits.Data.length === 0 && <span>No instances nearing platform limits</span>}
                {data.InstancesNearingPlatformLimits.Data
                    .map(alertingInstance => ({
                        ...alertingInstance,
                        ...this.alertSnoozer.shouldSnoozeInstanceNearingPlatformLimitAlert(alertingInstance.ResourceType, alertingInstance.HostedInstanceId, new Date())                            
                    }))
                    .sort(this.byNonSnoozedFirst)
                    .map(alertingInstance => ({
                        alertingInstance,
                        ...getAttentionIndicator(alertingInstance.AttentionLevel, alertingInstance.isSnoozed)
                    }))
                    .map(({alertingInstance, icon, color}) => (
                        <InstanceNearingPlatformLimitAlertItem key={`${alertingInstance.HostedInstanceId}-${alertingInstance.ResourceType}`}
                                             icon={icon}
                                             color={color}
                                             instanceNearingPlatformLimit={alertingInstance} 
                                             instanceLinkData={this.state.instances.get(alertingInstance.HostedInstanceId)}
                                             snoozedAlertData= {{isSnoozed: alertingInstance.isSnoozed, 
                                                                snoozeComment: alertingInstance.snoozeComment,
                                                                linkToCard: alertingInstance.linkToCard}}
                        />
                    ))}
            </Alerts>

            <Alerts header="Azure Resource Availability" lastUpdated={data.AzureResourceUsage.LastUpdated} freshness={"days"}>
                {data.AzureResourceUsage.Data
                    .map((usage) => {
                    const attentionIndicator = getAttentionIndicator(usage.AttentionLevel);

                    return <LimitItem key={usage.Name}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={usage.Name}
                                      value={usage.CurrentValue}
                                      limit={usage.Limit}/>;
                })}
            </Alerts>

            <Alerts header="Kubernetes Node Counts" lastUpdated={data.AzureKubernetesNodeCounts.LastUpdated} freshness={"hours"}>
                {data.AzureKubernetesNodeCounts.Data
                    .map(resource => ({
                        ...resource,
                        ...getAttentionIndicator(resource.AttentionLevel)
                    }))
                    .map(({KubernetesClusterName, NodeCount, icon, color}) => (
                        <AlertItem key={KubernetesClusterName}
                                   icon={icon}
                                   color={color}
                                   text={KubernetesClusterName}
                                   value={NodeCount}/>
                    ))}
            </Alerts>

            <Alerts header="Load Balancer Used TCP SNAT Ports 95th Over 72h" lastUpdated={data.LoadBalancerUsedTcpSnatPorts.LastUpdated} freshness={"hours"}>
                {data.LoadBalancerUsedTcpSnatPorts.Data
                  .map(resource => ({
                      ...resource,
                      ...getAttentionIndicator(resource.AttentionLevel)
                  }))
                  .map(({KubernetesClusterName, Count, icon, color}) => (
                    <AlertItem key={KubernetesClusterName}
                               icon={icon}
                               color={color}
                               text={KubernetesClusterName}
                               value={Count}/>
                  ))}
            </Alerts>

            <Alerts header="BU Monitor Limits" lastUpdated={data.BetterUptimeSummary.LastUpdated} freshness={"days"}>
                {[data.BetterUptimeSummary.Data.Usage].map((usage, index) => {
                    const attentionIndicator = getAttentionIndicator(usage.AttentionLevel);

                    return <LimitItem key={index}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      text={"Monitors in use"}
                                      value={usage.CurrentValue}
                                      limit={usage.Limit}/>;
                })}
            </Alerts>

            <Alerts header="Deployment status" lastUpdated={data.CloudPlatformDeploymentStatus.LastUpdated} freshness={"hours"}>
                {data.CloudPlatformDeploymentStatus.Data.map((deployment, index) => {
                    const attentionIndicator = getAttentionIndicator(deployment.AttentionLevel);

                    return <AlertItem key={index}
                                      icon={attentionIndicator.icon}
                                      color={attentionIndicator.color}
                                      title={deployment.Message}
                                      text={<span><ExternalLink href={deployment.ExternalLink} showIcon={false}>{deployment.ProjectName}</ExternalLink></span>}
                                      value={<span>{deployment.LastRunResult} <FriendlyLocalTime time={deployment.LastRun}/></span>}/>;
                })}
            </Alerts>
        </div>;
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private async loadData() {
        const [dashboard, reefsMap, instancesMap, workerServices] = await Promise.all([
            repository.Dashboard.get(),
            repository.Reefs.map(),
            repository.HostedInstances.lookup(),
            repository.DynamicWorkerServices.list()
        ]);

        return {
            data: dashboard,
            reefs: reefsMap,
            instances: new Map<string, HostedInstanceLookupResource>(instancesMap.map<[string, HostedInstanceLookupResource]>(r => [r.Id, r])),
            workerServices: new Map<string, DynamicWorkerServiceResource>(workerServices.map<[string, DynamicWorkerServiceResource]>(d => [d.Id, d]))
        };
    }

    private buildPodEvictionRow(entry: EvictionResource) {
        return [
            entry.PodName,
            <FriendlyLocalTime time={entry.EvictionDate}/>,
            entry.Message
        ];
    }

    private combineInstanceMetricUsageDetails(usage: InstanceMetricUsageResource[], instanceLookup: Map<string, HostedInstanceLookupResource>, metricType: DashboardMetricType): InstanceAlertDetails[] {
        return usage
            .filter(resource => instanceLookup.has(resource.HostedInstanceId))
            .map(resource => ({
                ...resource,
                ...instanceLookup.get(resource.HostedInstanceId)
            }))
            .map(resource => ({
                ...resource,
                isSnoozed: this.alertSnoozer.shouldSnoozeInstanceAlert(metricType, resource.ShortId)
            }))
            .sort(this.byNonSnoozedFirst)
            .map(({HostedInstanceId, AttentionLevel, Usage, DnsPrefix, isSnoozed, LastUpdated}) => ({
                HostedInstanceId,
                AttentionLevel,
                MetricValueInPercents: Usage,
                DnsPrefix,
                SnoozeAlert: isSnoozed,
                Title: "Metric for this instance last updated " + moment(LastUpdated).fromNow(),
            }));
    }

    private byNonSnoozedFirst(item1: {isSnoozed: boolean}, item2: {isSnoozed: boolean}) {
        return Number(item1.isSnoozed) - Number(item2.isSnoozed);
    }
}

export default Dashboard;
