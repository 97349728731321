import * as React from "react";
import {Checkbox} from "@octopusdeploy/design-system-components";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import repository from "client/repository";
import {AdvancedFilterTextInput} from "components/AdvancedFilterLayout";
import {HostedInstanceLookupResource} from "client/resources/hostedInstanceLookupResource";
import HostedInstanceMultiSelect from "shared/HostedInstanceMultiSelect";
import { Step } from "client/resources/step";
import { IntentResource } from "client/resources/intentResource";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import DateRangeIcon from "material-ui/svg-icons/action/date-range";
import DateRangePicker, {DateRange} from "components/DateRangePicker/DateRangePicker";
import {BackgroundProcessResource} from "client/resources/backgroundProcessResource";
import BackgroundTasksSummaryTable from "areas/backgroundTasks/BackgroundTasksSummaryTable";
import ProcessMultiSelect from "shared/ProcessMultiSelect";
import {Refresh} from "components/DataBaseComponent/DataBaseComponent";
import {IQuery, QueryStringFilters} from "components/QueryStringFilters/QueryStringFilters";
import {arrayValueFromQueryString} from "utils/ParseHelper/ParseHelper";
import BackgroundTaskStatusMultiSelect from "shared/BackgroundTaskStatusMultiSelect";
import BackgroundTaskStepMultiSelect from "shared/BackgroundTaskStepMultiSelect";
import BackgroundTaskIntentMultiSelect from "shared/BackgroundTaskIntentMultiSelect";
import moment from "moment";
import ActionList from "components/ActionList";
import { BackgroundTaskSummaryResource } from "client/resources/backgroundTaskSummaryResource";
import { getStepsByProcessIds } from "areas/backgroundTasks/getStepsByProcessIds";
import { getIntentsByProcessIds } from "areas/backgroundTasks/getIntentsByProcessIds";
import ActionButton from "../components/Button";
import {isEqual} from "lodash";
import {PagingInfo} from "../components/SimpleDataTable/SimpleDataTablePaging";


interface State extends DataBaseComponentState {
    tasks?: BackgroundTaskSummaryResource[];
    instances?: HostedInstanceLookupResource[];
    processes?: BackgroundProcessResource[];
    filter: Filter;
    steps?: Step[];
    intents?: IntentResource[];    
    paging: PagingInfo;
}

interface Filter {
    relatedTo?: string;
    relatedToInstances: string[];
    from: string;
    to: string;
    processIds: string[];
    statuses: string[];
    intents: string[];
    currentOrNextStepIds: string[];
    excludeRecurring: boolean;    
    skip: string;
}

interface Props {
    documentId?: string;
    excludeRecurring: boolean;  
    breadcrumbTitle?: string;
    breadcrumbChip?: JSX.Element;
    onChange?: () => void;
    getActions?: () => [];
}

interface Query extends IQuery, Filter {
}

class FilterLayout extends AdvancedFilterLayout<Filter> {
}

const TasksQueryStringFilters = QueryStringFilters.For<Filter, Query>();

class FilteredBackgroundTasks extends DataBaseComponent<Props, State> {
    
    private static getDefaultFilter(props: Props): Filter {
        return {
            relatedToInstances: [],
            from: moment().subtract(30, "days").startOf("day").toISOString(),
            to: moment().endOf("day").toISOString(),
            processIds: [],
            statuses: [],
            intents: [],
            currentOrNextStepIds: [],
            skip: "0",
            excludeRecurring: props.excludeRecurring
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            filter: FilteredBackgroundTasks.getDefaultFilter(props),
            paging: {
                take: 50,
                skip: 0,
                total: 0,
            }
        };
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.loadData(), 5000);
        });
    }

    async loadData() {
        const [paginatedTasks, instances, processes] = await Promise.all([
            this.loadTasks(),
            repository.HostedInstances.lookup(),
            repository.BackgroundTasks.listProcesses(),
        ]);

        const processIds = this.state.filter.processIds;
        const steps = getStepsByProcessIds(processIds, processes);
        const intents = getIntentsByProcessIds(processIds, processes);
        const paging = {
            ...this.state.paging,
            total: paginatedTasks.Total
        };
        
        if(this.props.onChange) {
            this.props.onChange();
        }
        
        return { tasks: paginatedTasks.Resources, instances, processes, steps, intents, paging};
    }

    loadTasks() {
        const filter = this.state.filter;
        const relatedTo = (this.props.documentId ? [this.props.documentId] : [])
            .concat(filter.relatedToInstances)
            .concat(filter.relatedTo ? [filter.relatedTo] : []);

        return repository.BackgroundTasks.listSummaries({
            relatedTo,
            skip: this.state.paging.skip,
            take: this.state.paging.take,
            from: filter.from,
            to: filter.to,
            processIds: filter.processIds,
            statuses: filter.statuses,
            intents: filter.intents,
            currentOrNextStepIds: filter.currentOrNextStepIds,
            excludeRecurring: filter.excludeRecurring
        });
    }

    reload(getNewFilter?: (prev: Filter) => Filter) {
        const filter = getNewFilter ? getNewFilter(this.state.filter) : this.state.filter;
        this.setState(
            {tasks: null, filter},
            () => this.doRefresh()
        );
    }

    render() {
        const state = this.state;
        const filter = state.filter;
        const initialLoadDone = !!this.state.instances;

        const list = state.tasks &&
            <BackgroundTasksSummaryTable tasks={state.tasks}
                                         processes={state.processes}
                                         onPageChange = {this.onPageChange.bind(this)}
                                         paging={state.paging} />;

        const filterSections = [
            {
                render: <div>
                    {!this.props.documentId && <AdvancedFilterTextInput
                        fieldName="related to document Id"
                        value={filter.relatedTo}
                        onChange={relatedTo => this.filterChanged({ relatedTo })}
                    />}
                    {!this.props.documentId && <HostedInstanceMultiSelect
                        items={state.instances}
                        value={filter.relatedToInstances}
                        onChange={relatedToInstances => this.filterChanged({ relatedToInstances })}/>}
                    <ProcessMultiSelect
                        items={state.processes}
                        value={filter.processIds}
                        onChange={processIds => this.filterChanged({ processIds })}/>
                    <BackgroundTaskStatusMultiSelect
                        value={filter.statuses}
                        onChange={statuses => this.filterChanged({ statuses })} />
                    <BackgroundTaskIntentMultiSelect
                        items={state.intents}
                        value={filter.intents}
                        onChange={intents => this.filterChanged({ intents })} />
                    <BackgroundTaskStepMultiSelect
                        items={state.steps}
                        value={filter.currentOrNextStepIds}
                        onChange={currentOrNextStepIds => this.filterChanged({ currentOrNextStepIds })} />
                    <Checkbox label="Exclude Recurring Tasks" 
                        value={filter.excludeRecurring}
                        onChange={excludeRecurring => this.filterChanged({ excludeRecurring})}  />
                </div>
            }
        ];

        return <PaperLayout title={this.calculateTitle()}
                      breadcrumbTitle = {this.props.breadcrumbTitle}
                      breadcrumbChip={this.props.breadcrumbChip}
                      busy={state.busy}
                      errors={state.errors}
                      sectionControl= { <ActionList actions={this.getActions()} /> }
                      fullWidth={true}>

            <TasksQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilterFromQuery} onFilterChange={this.filterChanged} />

            {initialLoadDone && <FilterLayout
                filter={state.filter}
                defaultFilter={FilteredBackgroundTasks.getDefaultFilter(this.props)}
                onFilterReset={() => this.reload(() => FilteredBackgroundTasks.getDefaultFilter(this.props))}
                filterSections={filterSections}
                additionalHeaderFilters={[this.getFilterHeader()]}
                renderContent={() => list}
            />
            }
        </PaperLayout>
        
    }
        
    private getActions() {
        const initialLoadDone = !!this.state.instances;
        const actions = [<ActionButton label="Refresh" onClick={this.doRefresh}/>];
        if (initialLoadDone && this.props.getActions) {
            actions.push(...this.props.getActions());
        }
        return actions;
    }

    public doRefresh: Refresh = () => Promise.resolve();
    
    public getTasks(){
        return this.state.tasks;
    }
    
    public isDefaultFilter() {
        return isEqual(this.state.filter, FilteredBackgroundTasks.getDefaultFilter(this.props));
    }

    private setFilterState<K extends keyof Filter>(state: Pick<Filter, K>) {
        this.reload(prev => ({...prev, ...state}));
    }

    private filterChanged = (filter: Partial<Filter>) => {
        this.setState({
            filter: {...this.state.filter, ...filter, skip: filter.skip ? filter.skip : "0"},
            paging: {
                ...this.state.paging,
                skip: filter.skip ? Number(filter.skip) : 0
            }
        }, async () => await this.doRefresh());
    };


    private queryFromFilters = (filter: Filter): Query => {
        return {
            ...filter
        };
    };

    private getFilterFromQuery = (query: Query): Filter => {
        return {
            ...query,
            relatedToInstances: arrayValueFromQueryString(query.relatedToInstances),
            processIds: arrayValueFromQueryString(query.processIds),
            statuses: arrayValueFromQueryString(query.statuses),
            intents: arrayValueFromQueryString(query.intents),
            currentOrNextStepIds: arrayValueFromQueryString(query.currentOrNextStepIds)
        };
    };

    private getFilterHeader() {
        // endDate is exclusive, so subtract a second to (possibly) get the previous day
        const dateRangeTitle = DateFormatter.dateToCustomFormat(this.state.filter.from, "D MMMM YYYY") + " - " +
            DateFormatter.dateToCustomFormat(moment(this.state.filter.to).subtract(1, "ms"), "D MMMM YYYY");
        return <OpenDialogButton
            label={dateRangeTitle}
            icon={<DateRangeIcon/>}
            wideDialog={true}>
            <DateRangePicker
                startDate={moment(this.state.filter.from)}
                endDate={moment(this.state.filter.to)}
                onSaveDone={async (range) => this.onDateRangePickerChange(range)}
            />
        </OpenDialogButton>;
    }

    public onPageChange(skip: number, page: number)
    {
        this.setState({
            paging: {
                ...this.state.paging,
                skip
            },
            filter: {
                ...this.state.filter,
                skip: skip.toString()
            }
        }, async () => await this.doRefresh());
    }

    private async onDateRangePickerChange(range: DateRange) {
        const filterSelections = {
            from: range.startDate.clone().startOf("day").toISOString(),
            to: range.endDate.clone().add(1, "days").startOf("day").toISOString()
        };

        this.setFilterState(filterSelections);
    }

    private calculateTitle() {
        if (!this.state.tasks) 
            return "Background Tasks";
        else 
            return `Background Tasks (${this.state.paging.total})`;
    }    
}

export default FilteredBackgroundTasks;