import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import ActionButton, {ActionButtonType, NavigationButtonType} from "components/Button";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn,
    DataTableRowHeaderColumn
} from "components/DataTable";
import {HostedInstanceLiveInformationResponse} from "client/resources/hostedInstanceLiveInformationResponse";
import {OctopusServerNodeSummaryResource} from "client/resources/octopusServerNodeSummaryResource";
import {Task} from "client/resources/task";
import {FriendlyLocalTime} from "shared/FriendlyLocalTime";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import Callout, {CalloutType} from "components/Callout";
import repository from "client/repository";
import ActionList from "../../../components/ActionList";
import {default as OverflowMenu} from "../../../components/Menu/OverflowMenu";
import {
    ActiveSqlTransaction,
    HostedInstanceActiveSqlTransactionsResponse
} from "client/resources/HostedInstanceActiveSqlTransactionsResponse";
import {AreYouReallySureConfirmation} from "./AreYouReallySureConfirmation";
import InfoDialogLayout from "../../../components/DialogLayout/InfoDialogLayout";
import NavigationButton from "../../../components/Button/NavigationButton";

interface Props {
    instanceId: string;
    breadcrumbTitle: string;
    breadcrumbChip: JSX.Element;
}

interface State extends DataBaseComponentState {
    info?: HostedInstanceLiveInformationResponse;
    transactions?: HostedInstanceActiveSqlTransactionsResponse;
    canTakeSystemReport: boolean;
}

class NodesDataTable extends SimpleDataTable<OctopusServerNodeSummaryResource> {
}

class ActiveTaskDataTable extends SimpleDataTable<Task> {
}

class ActiveTransactionsDataTable extends SimpleDataTable<ActiveSqlTransaction> {
}

class InstanceLiveInformation extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {canTakeSystemReport: false};
    }

    async componentDidMount() {
        this.refresh();
    }

    render() {

        const refreshButton = <ActionButton type={ActionButtonType.Primary}
                                            label="Refresh"
                                            busyLabel="Refreshing"
                                            disabled={this.state.busy || this.state.info && this.state.info.IsInactive}
                                            onClick={() => this.refresh()}/>;

        return <PaperLayout busy={this.state.busy}
                            errors={this.state.errors}
                            sectionControl={
                                <ActionList actions={[
                                    refreshButton, 
                                    <OverflowMenu menuItems={[
                                        OverflowMenu.dialogItem(
                                            "Download System Report",
                                            this.getSystemReportDialog()
                                        )]}
                                    />
                                ]} />}
                            title="Live Information"
                            breadcrumbTitle={this.props.breadcrumbTitle}
                            breadcrumbChip={this.props.breadcrumbChip}>
            {this.state.info && this.getBody()}
        </PaperLayout>;
    }

    private refresh() {
        return this.doBusyTask(async () => {
            this.setState({info: null, transactions: null});

            repository.HostedInstances.live(this.props.instanceId).then(info => this.setState({info}));
            repository.HostedInstances.activeTransactions(this.props.instanceId).then(transactions => this.setState({transactions}));
        });
    }

    private getBody() {
        const info = this.state.info;
        const transactions = this.state.transactions;

        return <div>
            {info.IsInactive &&
                <Callout type={CalloutType.Warning}>Unable to retrieve live data from this instance as it is inactive.</Callout>
            }
            {!info.IsInactive &&
                <div>
                    <Callout type={CalloutType.Information}>This data is retrieved directly from the live instance.</Callout>
                    <DataTable>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Version</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{info.Version}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Installation Id</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{info.InstallationId}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Is In Maintenance Mode</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{info.IsInMaintenanceMode ? "Yes" : "No"}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Health</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{info.HealthDescription}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Task Counts</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{this.getTaskCountDescription()}</DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                    <FormSectionHeading title={`Online Nodes (${info.Nodes.length})`}/>
                    <NodesDataTable data={info.Nodes}
                                    headerColumns={["Name", "Running/Max Tasks", "Draining", "Last Seen"]}
                                    onRow={n => [
                                        n.Name,
                                        n.RunningTaskCount + "/" + n.MaxConcurrentTasks,
                                        n.IsInMaintenanceMode ? "Yes" : "No",
                                        <FriendlyLocalTime time={n.LastSeen}/>
                                    ]}/>
                    <FormSectionHeading title={`Active Tasks (${info.ActiveTasks.length})`}/>
                    <ActiveTaskDataTable data={info.ActiveTasks}
                                         headerColumns={["Name", "State", "Queued", "Started", "Interruption"]}
                                         onEmpty={<div>No active tasks</div>}
                                         onRow={n => [
                                             n.Name,
                                             n.State,
                                             <FriendlyLocalTime time={n.QueueTime}/>,
                                             n.StartTime && <FriendlyLocalTime time={n.StartTime}/>,
                                             n.HasPendingInterruptions ? "Yes" : "No"
                                         ]}/>
                </div>
            }
            {transactions &&
                <div>
                    <FormSectionHeading title={`Active Transactions (${transactions.Transactions.length})`}/>
                    <ActiveTransactionsDataTable data={transactions.Transactions}
                                                 headerColumns={["Transaction Id", "Name", "Started", "Source"]}
                                                 onEmpty={<div>No active sql transactions</div>}
                                                 onRow={n => [
                                                    n.TransactionId,
                                                    n.Name,
                                                    <FriendlyLocalTime time={n.StartTime}/>,
                                                    n.ProgramName
                                                 ]}/>
                </div>
            }

        </div>;
    }

    private getTaskCountDescription() {
        const counts = this.state.info.TaskCounts;
        const result: string[] = [];

        function Add(key: string) {
            if (counts[key]) {
                result.push(counts[key] + " " + key);
            }
        }

        Add("Executing");
        Add("Cancelling");
        Add("Interrupted");
        Add("Queued");

        if (result.length === 0) {
            return "No active tasks";
        }

        return result.join(", ");
    }
    
    private getSystemReportDialog() {
        return <InfoDialogLayout title={"Download System Report"} errors={null} busy={null}>
            <AreYouReallySureConfirmation title={"This may cause a customer outage"}
                                          warning={<>Accessing the system report may cause the instance to <strong>restart</strong>, resulting in an outage for the customer.</>}
                                          requiredConfirmation={"instance may restart"}
                                          onChange={acknowledged => this.setState({canTakeSystemReport: acknowledged})} />
            <div>
                <br/>
                <NavigationButton href={repository.HostedInstances.linkToSystemReport(this.props.instanceId)} 
                                  label={"Download report"} 
                                  external={true}
                                  type={NavigationButtonType.Primary}
                                  disabled={!this.state.canTakeSystemReport}
                />
            </div>
        </InfoDialogLayout>
    }
}

export default InstanceLiveInformation;