import React from "react";
import {themeTokens} from "@octopusdeploy/design-system-tokens";
import BaseComponent from "components/BaseComponent";
import {HostedInstanceResource} from "client/resources/hostedInstanceResource";
import {outageWindowFrequencyDescriptions} from "client/resources/outageWindowFrequency";
import {mapMaintenanceWindowToHostedInstanceOutageWindowRequest} from "client/resources/maintenanceWindow";

const textColor = themeTokens.color.text;

interface Props {
    instance: HostedInstanceResource;
}

class OutageWindowDisplay extends BaseComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const instance = this.props.instance;

        return (
            <span style={{color: instance.IsCurrentlyInOutageWindow ? textColor.success : textColor.primary}}>
                {outageWindowFrequencyDescriptions[mapMaintenanceWindowToHostedInstanceOutageWindowRequest(instance.MaintenanceWindow).OutageWindowFrequency]}&nbsp;&mdash;&nbsp;{OutageWindowDisplay.formatTime(instance.MaintenanceWindow.StartTime)} - {OutageWindowDisplay.formatTime(instance.MaintenanceWindow.EndTime)} ({instance.MaintenanceWindow.TimeZone})
            </span>
        );
    }

    private static formatTime(value: string): string {
        // strip off the seconds
        return value.substring(0, value.length - 3);
    }
}

export default OutageWindowDisplay;
