import React from "react";

interface InstanceNameLinkProps {
    dnsPrefix: string;
    shortId: string;
    url: string;
}

const InstanceNameLink = (props: InstanceNameLinkProps): JSX.Element => {
    return <React.Fragment>
        <a href={props.url}>{props.dnsPrefix}</a> ({props.shortId})
    </React.Fragment>;
}

export default InstanceNameLink;
