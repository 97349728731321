import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Tooltip } from "@octopusdeploy/design-system-components";
import { CopyIcon } from "@octopusdeploy/design-system-icons";
import React, { useEffect, useState } from "react";
import { isTooltipHoverable } from "~/utils/TooltipHelper/isTooltipHoverable";

interface RichCopyToClipboardProps {
    value: string;
    richFragment?: string;
    showHoverTooltip?: boolean;
    children?: React.ReactNode;
}

const RichCopyToClipboardButton: React.FC<RichCopyToClipboardProps> = ({ value, richFragment, children, showHoverTooltip = true }) => {
    const [event, setEvent] = useState<React.MouseEvent | null>(null);

    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (event) {
            setShowConfirmation(true);

            const timerId = window.setTimeout(() => setShowConfirmation(false), 3500);

            return () => {
                window.clearTimeout(timerId);
            };
        }
    }, [event]);

    const onClick = (e: React.MouseEvent) => {
        const copy = async () => {
            const items: Record<string, Blob> = {"text/plain": new Blob([value], {type: "text/plain"})};
            if (richFragment) {items["text/html"] = richFragment && new Blob([richFragment], {type: "text/html"});}
            const clipboardContent = new ClipboardItem(items);
            await navigator.clipboard.write([clipboardContent]);

            setEvent(e);
        };

        copy();
    };

    // Setting the <ToolTip /> `trigger` prop as `undefined` uses the default value: `mouseenter focus`
    const outerTooltipTrigger = showConfirmation ? "manual" : undefined;

    const innerTooltipOpen = showConfirmation ? true : false;

    return (
        <span>
            <Tooltip content="Copy to clipboard" trigger={outerTooltipTrigger} open={isTooltipHoverable(showHoverTooltip && !showConfirmation)}>
                <Tooltip content="Copied!" trigger="manual" open={innerTooltipOpen}>
                    <span>
                        {children ? (
                            <span onClick={onClick}>{children}</span>
                        ) : (
                            <div className={copyButtonWrapper}>
                                <ActionButton onClick={onClick} label={""} icon={<CopyIcon size={24} />} iconPosition="left" aria-label={`Copy to clipboard`} type={ActionButtonType.Ternary} />
                            </div>
                        )}
                    </span>
                </Tooltip>
            </Tooltip>
        </span>
    );
};

const copyButtonWrapper = css({
    "&& .MuiButton-root": {
        aspectRatio: "1",
        padding: "0",
        borderRadius: "50%",
        minWidth: "auto",
    },
    "&& .MuiButton-root .MuiButton-startIcon": {
        margin: "0",
    },
});

export default RichCopyToClipboardButton;
